export const ADMISSION_CARDS_TYPE = {
    DOCTOR_DATA: 'doctorData',
    DAYS_DATA: 'daysData',
    DX_DATA: 'dxData',
    INSURANCE_DATA: 'insuranceData',
    FLOORS_DATA: 'floorsData',
    HOSPITAL_DATA: 'hospitalData',
    SIXTY_DAYS_DATA: 'sixtyDaysData',
    TOTAL: "total",
    ADMISSION: "admission",
    READMISSION: "readmission",
    TOTAL_ADMISSIONS: "totalAdmissions",
    TOTAL_READMISSIONS: "totalReAdmissions",
    ADT_TAB: "adt"
}

export const ADMISSION_FILTER_TYPE = {
    ADT: "ADT",
    ADMISSION: "Admission",
    MANUAL: "Manual"
}

export const ADMISSION_CARDS_LABELS = {
    total: "Total Admissions",
    admission: "New Admissions",
    readmission: "Re-Admissions",
    doctorData: 'Per Doctor',
    daysData: 'Per Day',
    dxData: 'Per Diagnosis',
    insuranceData: 'Per Insurance',
    floorsData: 'Per Floor',
    hospitalData: 'Per Hospital',
}
