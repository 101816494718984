/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import { Grid, Stack } from "@mui/material";
import _ from "lodash";
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEFAULT_CHART_DATA, DEFAULT_CHART_FILTER, percentageByEnum } from "../../../data/common.data";
import { getAccountQuestions } from "../../../services/api/user.api";
import { cardPatientAndChartData } from "../../../services/hospital.service";
import { closeDetailsDialog } from "../../../store/reducers/hospital.slice";
import { CHART_POINT_TYPE, CHART_TAB_BUTTON } from "../../../types/chart-dialog.type";
import { CHART_FILTER_DAY_OF, DASHBOARD_FILTER_TYPE, FILTER_TYPES } from "../../../types/common.type";
import { HOSPITAL_CARDS_TYPE } from "../../../types/hospital.type";
import {
	chartFilterPermission,
	chartGroupBy,
	chartsData,
	dayDifferent,
	getHospitalFieldNames,
	isUpdateDefaultFilter,
	selectedCardIds,
} from "../../../utilis/charts-common";
import { getChartFacilityPercentageBy, getPercentageByTotal, getPercentageByType, isOnlyHospitalTabAccess, patientDataOrderBy } from "../../../utilis/common";
import ChartDetailsTab from "../../shared/chart-dialog-common/ChartDetailsTab";
import ChartDialogContainer from "../../shared/chart-dialog-common/ChartDialogContainer";
import ChartDialogLeftSidebar from "../../shared/chart-dialog-common/ChartDialogLeftSidebar";
import ChartTabLoader from "../../shared/chart-dialog-common/ChartTabLoader";
import ChartBuildingList from "../../shared/chart-dialog-common/chart-building/ChartBuildingList";
import CardDetailChart from "../../shared/chart-dialog-common/chart-tab/CardDetailChart";
import ChartTableList from "../../shared/chart-dialog-common/chart-table/ChartTableList";
import ChartDetailsSearchBar from "../../shared/chart-dialog-common/ChartDetailsSearchBar";
import NoteContainer from "../../shared/NoteContainer";
import { PAGE_TYPE } from "../../../types/pages.type";

export default function HospitalCardDetailsDialog({ handleOnClickReport, handleGenerateExcelReport }) {
	const tableElementRef = useRef();
	const buildingElementRef = useRef();
	const [selectedItem, setSelectedItem] = useState([]);
	const isOnlyHospitalDashboard = useMemo(() => {
		return isOnlyHospitalTabAccess();
	}, []);

	const [loading, setLoading] = useState(false);
	const [buttonFilterType, setButtonFilterType] = useState(CHART_FILTER_DAY_OF.DAY);
	const [censusAverage, setCensusAverage] = useState([]);
	const [censusByPeriod, setCensusByPeriod] = useState([]);
	const [censusByFacility, setCensusByFacility] = useState([]);
	const [bedByFacility, setBedByFacility] = useState([]);
	const [queryFilters, setQueryFilters] = useState(DEFAULT_CHART_FILTER);
	const [selectedTab, setSelectedTab] = useState(CHART_TAB_BUTTON.TABLE);
	const [isShowPercentage, setIsShowPercentage] = useState(false);
	const [defaultPatientData, setDefaultPatientData] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [chartData, setChartData] = useState(DEFAULT_CHART_DATA);
	const dispatch = useDispatch();
	const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES.DAILY);
	const {
		detailsDialog: { isOpen, title, data, filters, dbData },
	} = useSelector((state) => state.hospital);
	const { auth } = useSelector(({ auth }) => ({ auth }));
	const [orderBy, setOrderBy] = useState('dateOfADT'); // Default sort column
	const [order, setOrder] = useState('asc'); // Default sort direction
	// COMPARISON STATES ---------->
	const {
		detailsDialog: {
			isOpen: isOpenComparison,
			title: titleComparison,
			data: dataComparison,
			filters: filtersComparison,
		},
		detailsDialog: detailsDialogComparison,
	} = useSelector((state) => state.hospitalComparison);
	const [chartDataComparison, setChartDataComparison] = useState(DEFAULT_CHART_DATA);
	const [censusAverageComparison, setCensusAverageComparison] = useState([]);
	const [censusByPeriodComparison, setCensusByPeriodComparison] = useState([]);
	const [censusByFacilityComparison, setCensusByFacilityComparison] = useState([]);
	const [queryFiltersComparison, setQueryFiltersComparison] = useState(DEFAULT_CHART_FILTER);
	const [defaultPatientDataComparison, setDefaultPatientDataComparison] = useState([]);
	const [filterDataComparison, setFilterDataComparison] = useState([]);
	const [selectedItemComparison, setSelectedItemComparison] = useState([]);
	const [questions, setQuestions] = React.useState([]);

	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);

	const [selectedFacility, setSelectedFacility] = useState(activeFacilities);

	const getQuestions = async (activeFacilitiesData) => {
		const res = await getAccountQuestions({
			facilityid: activeFacilitiesData,
			accountId: auth.accountId,
			pageType: DASHBOARD_FILTER_TYPE.HOSPITAL,
		});
		if (res && res.length > 0) {
			setQuestions(res);
		}
	};

	React.useEffect(() => {
		getQuestions(activeFacilities);
		// eslint-disable-next-line
	}, [activeFacilities]);

	// * added comparison
	const updateChartArrData = useCallback(
		async (dataArr, filterType = null, type = null, filterObj, forComparison) => {
			try {
				let filterBy = filterType;
				if (!filterType) {
					filterBy = buttonFilterType;
				}
				let chartDataArr = [];
				if (type == "filter" && filterObj) {
					chartDataArr = await chartsData(dataArr, filterObj);
				} else {
					chartDataArr = await chartsData(dataArr, queryFilters);
				}

				let filtersLatest = filterObj ? filterObj : forComparison ? queryFiltersComparison : queryFilters;
				const latestChartData = await chartGroupBy(
					chartDataArr,
					filterBy,
					forComparison ? censusAverageComparison : censusAverage,
					forComparison ? censusByPeriodComparison : censusByPeriod,
					filtersLatest
				);
				if (type == "filter") {
					forComparison
						? setChartDataComparison((prevState) => ({
							...prevState,
							filterData: latestChartData,
							filterPatients: dataArr,
						}))
						: setChartData((prevState) => ({
							...prevState,
							filterData: latestChartData,
							filterPatients: dataArr,
						}));
				} else {
					forComparison
						? setChartDataComparison((prevState) => ({
							...prevState,
							filterData: latestChartData,
						}))
						: setChartData((prevState) => ({
							...prevState,
							filterData: latestChartData,
						}));
				}
			} catch (e) {
				console.log(e);
			}
		},
		[
			buttonFilterType,
			censusAverage,
			censusAverageComparison,
			censusByPeriod,
			censusByPeriodComparison,
			queryFilters,
			queryFiltersComparison,
		]
	);
	// * added comparison.
	const filterOptions = useCallback(
		async (
			newChecked,
			mainDataArr,
			latestButtonFilterType = null,
			filterObj = null,
			filterType = "initial",
			originalData,
			forComparison
		) => {
			let mainData = mainDataArr;
			if (activeFacilities.length > 1) {
				const filterPatientsByFacility = _.filter(mainData, ({ facilityId }) => _.every([_.includes(selectedFacility, facilityId)]));
				mainData = filterPatientsByFacility
			}
			if (filters.type === HOSPITAL_CARDS_TYPE.DX_DATA) {
				let newChartFilters = _.filter(mainData, ({ dxIds }) => {
					const matchedIds = _.intersection(newChecked, dxIds);
					if (matchedIds.length > 0) {
						return true;
					} else {
						return false;
					}
				});
				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
			} else if (filters.type === HOSPITAL_CARDS_TYPE.NINETY_DAYS_DATA) {
				let ninetyDaysDataIds = [];
				let dataOriginal = filterObj?.filterData
					? filterObj.filterData
					: forComparison
						? filterDataComparison
						: filterData;

				if (filterType === "reload") {
					dataOriginal = originalData.ninetyDaysData;
				}
				let ninetyDaysDataFilter = _.filter(dataOriginal, ({ _id }) => _.every([_.includes(newChecked, _id)]));
				if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
					ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
				}

				let newChartFilters = _.filter(mainData, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
			} else if (filters.type === HOSPITAL_CARDS_TYPE.DAYS_DATA) {
				let newChartFilters = _.filter(mainData, ({ day }) => {
					return _.every([_.includes(newChecked, day)]);
				});
				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
			} else if (filters.type === HOSPITAL_CARDS_TYPE.SHIFT_DATA) {
				let newChartFilters = _.filter(mainData, ({ shiftName }) => {
					return _.every([_.includes(newChecked, shiftName)]);
				});
				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
			} else if (filters.type === HOSPITAL_CARDS_TYPE.HOSPITALIZATIONS) {
				if (newChecked.length === 1) {
					let filter = Object();
					if (newChecked[0] == "newHospitalizations") {
						filter.reHospitalization = false;
					}
					if (newChecked[0] == "reHospitalizations") {
						filter.reHospitalization = true;
					}
					let newChartFilters = _.filter(mainData, filter);
					await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
				} else {
					await updateChartArrData(mainData, latestButtonFilterType, "filter", filterObj, forComparison);
				}
			} else if (filters.type === HOSPITAL_CARDS_TYPE.DCER_DATA) {
				if (newChecked.length === 1) {
					let filter = Object();
					if (newChecked[0] === "DC") {
						filter.wasAdmitted = true;
					}
					if (newChecked[0] === "ER") {
						filter.wasAdmitted = false;
					}
					let newChartFilters = _.filter(mainData, filter);

					await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
				} else {
					await updateChartArrData(mainData, latestButtonFilterType, "filter", filterObj, forComparison);
				}
			} else if (filters.type === HOSPITAL_CARDS_TYPE.RETURNS_DATA) {
				let filter = Object();
				if (newChecked.length === 1) {
					if (newChecked[0] === "Returned") {
						filter.wasReturned = true;
					}
					if (newChecked[0] === "Didn't Return") {
						filter.wasReturned = false;
					}
					let newChartFilters = _.filter(mainData, filter);
					await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
				} else {
					await updateChartArrData(mainData, latestButtonFilterType, "filter", filterObj, forComparison);
				}
			} else {
				const isTotalCard = filters.type === HOSPITAL_CARDS_TYPE.TOTAL ||
					filters.type === HOSPITAL_CARDS_TYPE.PLANNED ||
					filters.type === HOSPITAL_CARDS_TYPE.UNPLANNED;
				let newChartFilters = [];
				if (!isTotalCard) {
					newChartFilters = _.filter(mainData, ({ filterId }) => {
						return _.every([_.includes(newChecked, filterId)]);
					});
				} else {
					newChartFilters = mainData;
				}
				await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
			}
		},
		[filterData, filterDataComparison, filters.type, updateChartArrData, selectedFacility, activeFacilities]
	);

	useMemo(() => {
		filterOptions(
			selectedItem,
			chartData?.mainData,
			buttonFilterType,
			{
				...filters?.filter,
			},
			undefined,
			undefined,
			false
		);
		// c
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFacility]);

	// * added comparison
	const getPatientChartsData = async () => {
		try {
			setLoading(true);
			if (
				filters.type === HOSPITAL_CARDS_TYPE.TOTAL ||
				filters.type === HOSPITAL_CARDS_TYPE.PLANNED ||
				filters.type === HOSPITAL_CARDS_TYPE.UNPLANNED
			) {
				setIsShowPercentage(true);
			}
			const [ids, idsComparison] = await Promise.all([
				selectedCardIds(data, filters.type),
				dataComparison && selectedCardIds(dataComparison, filtersComparison?.type),
			]);
			const [response, responseComparison] = await Promise.all([
				cardPatientAndChartData({ ...filters, ids }, "main"),
				rangesSet && (filtersComparison || idsComparison) && cardPatientAndChartData({ ...filtersComparison, idsComparison }, "comparison"),
			]);

			let latestButtonFilterType = buttonFilterType;
			const isUpdateFilter = isUpdateDefaultFilter(filters.filter);
			if (isUpdateFilter) {
				setSelectedFilter(FILTER_TYPES.WEEKLY);
				latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
				setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
			}
			if (response && response.data) {
				let censusAverageData = String(getPercentageByTotal(response));
				setCensusAverage(censusAverageData);
				setCensusByPeriod(response?.censusByPeriod || []);
				const censusByData = await getChartFacilityPercentageBy(response)
				setCensusByFacility(censusByData);
				const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
				setDefaultPatientData(responseData);
				const chartDataArr = await chartsData(responseData, filters.filter);
				const latestChartData = await chartGroupBy(
					chartDataArr,
					latestButtonFilterType,
					censusAverageData,
					response?.censusByPeriod,
					filters.filter
				);
				setChartData({
					mainData: responseData,
					filterPatients: responseData,
					filterData: latestChartData,
				});
			}
			await setFilterData(data);
			setTimeout(async () => {
				if (filters.selectedFilterData && filters.selectedFilterData.length > 0) {
					setSelectedItem([...filters.selectedFilterData]);
					const responseDataFilter = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
					await filterOptions(filters.selectedFilterData, responseDataFilter, latestButtonFilterType, {
						...filters.filter,
						filterData: data,
					});
				} else {
					const selectedIds = _.map(data, "_id") || [];
					setSelectedItem(selectedIds);
				}
				if (responseComparison && !responseComparison.data) {
					setLoading(false);
				}
			}, 2000);

			if (responseComparison && responseComparison.data) {
				setCensusAverageComparison(responseComparison.censusAverage);
				setCensusByPeriodComparison(responseComparison?.censusByPeriod || []);
				if (responseComparison.censusByFacility) {
					setCensusByFacilityComparison(responseComparison?.censusByFacility || []);
				}

				const responseDataComparison = responseComparison.data;
				setDefaultPatientDataComparison(responseDataComparison);
				const chartDataArr = await chartsData(responseDataComparison, filtersComparison?.filter);
				const latestChartData = await chartGroupBy(
					chartDataArr,
					latestButtonFilterType,
					responseComparison.censusAverage,
					responseComparison?.censusByPeriod,
					filtersComparison?.filter
				);
				setChartDataComparison({
					mainData: responseDataComparison,
					filterPatients: responseDataComparison,
					filterData: latestChartData,
				});
			}

			setFilterDataComparison(dataComparison);
			setTimeout(async () => {
				if (filtersComparison?.selectedFilterData && filtersComparison?.selectedFilterData.length > 0) {
					setSelectedItemComparison([...filtersComparison?.selectedFilterData]);
					await filterOptions(
						filtersComparison?.selectedFilterData,
						responseComparison.data,
						latestButtonFilterType,
						{
							...filtersComparison?.filter,
							filterData: dataComparison,
						},
						undefined,
						undefined,
						true
					);
				} else {
					const selectedIds = _.map(dataComparison, "_id") || [];
					setSelectedItemComparison(selectedIds);
				}
				setLoading(false);
			}, 2000);
		} catch (e) {
			console.log(e);
		}
	};

	// * added comparison
	const reloadChartData = useCallback(
		async (startDate, endDate, startDateComparison, endDateComparison) => {
			try {
				setLoading(true);
				let filterObj = { ...filters, filter: { startDate, endDate } };
				let filterObjComparison = {
					...filtersComparison,
					filter: { startDate: startDateComparison, endDate: endDateComparison },
				};

				let latestButtonFilterType = buttonFilterType;
				const isUpdateFilter = isUpdateDefaultFilter(filterObj.filter);

				if (isUpdateFilter) {
					setSelectedFilter(FILTER_TYPES.WEEKLY);
					latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
					setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
				}
				const [ids, idsComparison] = await Promise.all([
					selectedCardIds(data, filters.type),
					selectedCardIds(dataComparison, filters?.type),
				]);

				const [response, responseComparison] = await Promise.all([
					cardPatientAndChartData({ ...filterObj, ids }),
					(filterObjComparison || idsComparison) &&
					cardPatientAndChartData({ ...filterObjComparison, ids: idsComparison }),
				]);

				if (response && response.data && response.data.length > 0) {
					setLoading(false);
					let censusAverageData = String(getPercentageByTotal(response));
					setCensusAverage(censusAverageData);

					setCensusByPeriod(response?.censusByPeriod || []);

					const censusByData = await getChartFacilityPercentageBy(response)
					setCensusByFacility(censusByData);

					// if (percentageBy === percentageByEnum.bedCapacity) {
					// 	if (response.censusByFacility) {
					// 		setCensusByFacility(response?.bedByFacility || []);
					// 	}
					// } else {
					// 	if (response.censusByFacility) {
					// 		setCensusByFacility(response?.censusByFacility || []);
					// 	}
					// }
					// if (response.bedByFacility) {
					// 	setBedByFacility(response?.bedByFacility || []);
					// }
					const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } })
					const chartDataArr = await chartsData(responseData, filterObj.filter);
					const latestChartData = await chartGroupBy(
						chartDataArr,
						latestButtonFilterType,
						censusAverageData,
						response?.censusByPeriod,
						filterObj.filter
					);
					setChartData({
						mainData: responseData,
						filterPatients: responseData,
						filterData: latestChartData,
					});
					let originalChartData = [];
					if (response.ninetyDaysData && response.ninetyDaysData.length > 0 && filters.type === "ninetyDaysData") {
						originalChartData = response.ninetyDaysData;
					}
					if (filterData.length > 0 && originalChartData.length > 0) {
						const latestFilterData = filterData
							.map((eleFilter) => {
								const selectedEle = _.find(originalChartData, { _id: eleFilter._id });
								if (selectedEle) {
									return {
										...eleFilter,
										admissionIds: selectedEle.admissionIds,
										ids: selectedEle.ids,
									};
								} else {
									return null;
								}
							})
							.filter((item) => item);
						setFilterData(latestFilterData);
					}
					if (selectedItem.length > 0) {
						await filterOptions(
							selectedItem,
							responseData,
							latestButtonFilterType,
							filterObj.filter,
							"reload",
							response
						);
					}
				}
				if (responseComparison && responseComparison.data && responseComparison.data.length > 0) {
					setLoading(false);
					setCensusAverageComparison(responseComparison.censusAverage);
					setCensusByPeriodComparison(responseComparison.censusByPeriod || []);

					if (responseComparison.censusByFacility) {
						setCensusByFacilityComparison(responseComparison.censusByFacility || []);
					}

					const chartDataArrComparison = await chartsData(responseComparison.data, filterObjComparison.filter);
					const latestChartDataComparison = await chartGroupBy(
						chartDataArrComparison,
						latestButtonFilterType,
						responseComparison.censusAverage,
						responseComparison.censusByPeriod,
						filterObjComparison.filter
					);
					setChartDataComparison({
						mainData: responseComparison.data,
						filterPatients: responseComparison.data,
						filterData: latestChartDataComparison,
					});
					let originalChartDataComparison = [];
					if (
						responseComparison.ninetyDaysData &&
						responseComparison.ninetyDaysData.length > 0 &&
						filtersComparison?.type === "ninetyDaysData"
					) {
						originalChartDataComparison = responseComparison.ninetyDaysData;
					}
					if (filterDataComparison.length > 0 && originalChartDataComparison.length > 0) {
						const latestFilterDataComparison = filterDataComparison
							.map((eleFilter) => {
								const selectedEle = _.find(originalChartDataComparison, { _id: eleFilter._id });
								if (selectedEle) {
									return {
										...eleFilter,
										admissionIds: selectedEle.admissionIds,
										ids: selectedEle.ids,
									};
								} else {
									return null;
								}
							})
							.filter((item) => item);
						setFilterDataComparison(latestFilterDataComparison);
					}
					if (selectedItemComparison.length > 0) {
						await filterOptions(
							selectedItemComparison,
							responseComparison.data,
							latestButtonFilterType,
							filterObjComparison.filter,
							"reload",
							responseComparison,
							true
						);
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		},
		[
			filters,
			filtersComparison,
			buttonFilterType,
			data,
			dataComparison,
			filterData,
			selectedItem,
			filterOptions,
			filterDataComparison,
			selectedItemComparison,
		]
	);

	// * added comparison
	const reset = () => {
		setSelectedItem([]);
		setDefaultPatientData([]);
		setFilterData([]);

		setSelectedItemComparison([]);
		setFilterDataComparison([]);
		setDefaultPatientDataComparison([]);
	};

	// * added comparison
	const handleToggle = useCallback(
		async (value) => {
			const currentIndex = selectedItem.indexOf(value);
			const newChecked = [...selectedItem];
			if (currentIndex === -1) {
				newChecked.push(value);
			} else {
				newChecked.splice(currentIndex, 1);
			}

			if (newChecked.length > 0) {
				await Promise.all([
					filterOptions(newChecked, chartData.mainData, buttonFilterType),
					filterOptions(
						newChecked,
						chartDataComparison.mainData,
						buttonFilterType,
						undefined,
						undefined,
						undefined,
						true
					),
				]);
			} else {
				setChartData((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setChartDataComparison((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
			}
			setSelectedItem(newChecked);
		},
		[selectedItem, filterOptions, chartData.mainData, buttonFilterType, chartDataComparison.mainData]
	);

	// * added comparison
	const handleClickFilter = useCallback(
		async (type, filterType = null) => {
			setSelectedFilter(type);
			setButtonFilterType(filterType);
			await Promise.all([
				await updateChartArrData(chartData.filterPatients, filterType),
				await updateChartArrData(chartDataComparison.filterPatients, filterType, undefined, undefined, true),
			]);
			if (selectedItem.length > 0) {
				await Promise.all([
					await filterOptions(selectedItem, chartData.mainData, filterType),
					await filterOptions(
						selectedItem,
						chartDataComparison.mainData,
						filterType,
						undefined,
						undefined,
						undefined,
						true
					),
				]);
			} else {
				await Promise.all([
					await updateChartArrData(chartData.mainData, filterType),
					await updateChartArrData(chartDataComparison.mainData, filterType, undefined, undefined, true),
				]);
			}
		},
		[
			updateChartArrData,
			chartData.filterPatients,
			chartData.mainData,
			chartDataComparison?.filterPatients,
			chartDataComparison?.mainData,
			selectedItem,
			filterOptions,
		]
	);

	// * added comparison
	const handleChartLabelType = useCallback(
		async (type) => {
			setQueryFilters((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			setQueryFiltersComparison((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			let mainChartData = [];
			let comparisonMainChartData = [];
			if (selectedItem.length > 0) {
				mainChartData = chartData.filterPatients;
				comparisonMainChartData = chartDataComparison.filterPatients;
			} else {
				mainChartData = chartData.mainData;
				comparisonMainChartData = chartDataComparison.mainData;
			}
			await Promise.all([
				updateChartArrData(mainChartData, null),
				updateChartArrData(comparisonMainChartData, null, undefined, undefined, true),
			]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[updateChartArrData, chartData.filterPatients, chartDataComparison?.filterPatients, selectedItem]
	);

	// * added comparison
	const handleToggleAll = useCallback(
		async (value) => {
			if (value == "all") {
				const ids = filterData?.map((a) => a._id);
				const idsComparison = filterDataComparison?.map((x) => x._id);
				await Promise.all([
					filterOptions(ids, chartData.mainData, buttonFilterType),
					filterOptions(
						idsComparison,
						chartDataComparison.mainData,
						buttonFilterType,
						undefined,
						undefined,
						undefined,
						true
					),
				]);
				setSelectedItem([...ids]);
			} else {
				setChartData((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setSelectedItem([]);
				setChartDataComparison((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setSelectedItemComparison([]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filterData, chartData.mainData, buttonFilterType, chartDataComparison.mainData]
	);

	const onClickReport = useCallback(
		(title, automaticallyReport) => {
			handleOnClickReport({
				selectedTab,
				filters,
				chartData: {
					...chartData,
					filterPatients: patientDataOrderBy(chartData?.filterPatients, order, orderBy)
				},
				chartDataComparison,
				selectedFilter,
				tableElementRef,
				buildingElementRef,
				title,
				queryFilters,
				selectedItem,
				filterData,
				automaticallyReport,
				buttonFilterType,
				dbData,
				orderBy,
				order,
				activeFacilitiesCount: activeFacilities?.length
			});
		},
		[
			handleOnClickReport,
			selectedTab,
			filters,
			chartData,
			chartDataComparison,
			selectedFilter,
			queryFilters,
			selectedItem,
			filterData,
			buttonFilterType,
			dbData,
			orderBy,
			order,
			activeFacilities
		]
	);

	const handleClose = useCallback(() => {
		dispatch(closeDetailsDialog());
	}, [dispatch]);

	const handleTabButton = useCallback((type) => {
		setSelectedTab(type);
	}, []);

	const getFieldNames = useCallback(
		(row) => {
			return getHospitalFieldNames(data, row, filters?.relation, filters?.type);
		},
		[data, filters?.relation, filters?.type]
	);
	const { rangesSet } = useSelector((state) => state.comparison);
	// * added comparison
	useEffect(() => {
		reset();
		if (filters && filters.filter) {
			const daysFilter = chartFilterPermission(filters.filter);
			const daysDiff = dayDifferent(filters.filter);
			setQueryFilters({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filters.filter.startDate,
				endDate: filters.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}

		if (filtersComparison && filtersComparison?.filter && rangesSet) {
			const daysFilter = chartFilterPermission(filtersComparison?.filter);
			const daysDiff = dayDifferent(filtersComparison?.filter);
			setQueryFiltersComparison({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filtersComparison?.filter.startDate,
				endDate: filtersComparison?.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters?.filter, rangesSet, filtersComparison?.filter]);

	const handleExcelReport = useCallback((selectedColumns = []) => {
		handleGenerateExcelReport({
			data: chartData?.filterPatients,
			filters,
			pageType: DASHBOARD_FILTER_TYPE.HOSPITAL,
			getFieldNames,
			title,
			censusAverage,
			bedCapacity: dbData?.bedCapacity || 0,
			questions,
			selectedColumns,
			orderBy,
			order,
			activeFacilitiesCount: activeFacilities?.length
		});
	}, [
		chartData?.filterPatients,
		filters,
		getFieldNames,
		handleGenerateExcelReport,
		title,
		censusAverage,
		dbData,
		questions,
		orderBy,
		order,
		activeFacilities
	]);

	// below code use for search filter in table
	const [searchTerm, setSearchTerm] = useState('');
	const [totalHighlightedCount, setTotalHighlightedCount] = useState(0);
	const [debounceHighlightedCount, setDebounceHighlightedCount] = useState(0);
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
	const [highlightedCountDefaultValue, setHighlightedCountDefaultValue] = useState(0);
	const [selectedHighlightedColumns, setSelectedHighlightedColumns] = useState([]);

	// useEffect(() => {
	// 	if (questions && questions.length > 0) {
	// 		setSelectedHighlightedColumns([...questions?.map((ele) => ele?.question?.accessor), ...tableDefaultQuestions.map((ele) => ele?.question?.accessor), "selectAll"]);
	// 	}
	// }, [questions]);

	const [filterType, setFilterType] = useState({ type: '', filter: '', operation: '' });

	useEffect(() => {
		const handlerCount = _.debounce(() => setDebounceHighlightedCount(totalHighlightedCount), 200);
		handlerCount();
		return () => handlerCount.cancel();
	}, [totalHighlightedCount]);

	useEffect(() => {
		const handler = _.debounce(() => {
			setDebouncedSearchTerm(searchTerm);
			setFilterType({ type: typeof searchTerm, filter: searchTerm, operation: '' });
		}, 300);
		handler();
		return () => handler.cancel();
	}, [searchTerm]);

	const handleSearch = (event) => {
		const value = event.target.value;
		setSearchTerm(isNaN(value) || !value ? value : Number(value));
	};

	const handleHighlightedCount = useCallback((count) => {
		setTotalHighlightedCount(searchTerm ? count : 0);
	}, [searchTerm]);

	// end of below code use for search filter in table

	return (
		<ChartDialogContainer handleClose={handleClose} isOpen={isOpen}>
			<ChartDialogLeftSidebar
				loading={loading}
				filterData={filterData}
				selectedItem={selectedItem}
				handleToggle={handleToggle}
				handleToggleAll={handleToggleAll}
				title={title}
				selectedTab={selectedTab}
			/>
			<Grid item xs={10.7} style={{ padding: "30px" }}>
				<ChartDetailsTab
					selectedTab={selectedTab}
					handleTabButton={handleTabButton}
					queryFilters={queryFilters}
					handleOnClickReport={onClickReport}
					loading={loading}
					handleExcelReport={handleExcelReport}
					setSelectedFacility={setSelectedFacility}
					selectedFacility={selectedFacility}
					questions={questions}
					pageType={DASHBOARD_FILTER_TYPE.HOSPITAL}
					tableFilterTypeSetting={DASHBOARD_FILTER_TYPE.HOSPITAL}
				/>
				{selectedTab === CHART_TAB_BUTTON.TABLE &&
					<ChartDetailsSearchBar
						searchTerm={searchTerm}
						handleSearch={handleSearch}
						setSearchTerm={setSearchTerm}
						highlightedCount={debounceHighlightedCount}
						filterType={filterType}
						setFilterType={setFilterType}
						selectedHighlightedColumns={selectedHighlightedColumns}
						setSelectedHighlightedColumns={setSelectedHighlightedColumns}
						selectedQuestions={questions}
						selectedTab={selectedTab}
					/>
				}
				{!loading && (
					<NoteContainer page={PAGE_TYPE.HOSPITAL} openFrom="dialog">
						<>
							{selectedTab === CHART_TAB_BUTTON.BUILDING && (
								<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
									<ChartBuildingList
										page={DASHBOARD_FILTER_TYPE.HOSPITAL}
										data={chartData?.filterPatients || []}
										relation={filters?.relation}
										getFieldNames={getFieldNames}
										chartData={chartData}
										censusByFacility={censusByFacility}
										bedByFacility={bedByFacility}
										filterSelected={filters.filterSelected}
										filter={filters}
										buildingElementRef={buildingElementRef}
										dataComparison={chartDataComparison?.filterPatients || []}
										relationComparison={filtersComparison?.relation}
										chartDataComparison={chartDataComparison}
										censusByFacilityComparison={censusByFacilityComparison}
										filterSelectedComparison={filtersComparison?.filterSelected}
										filterComparison={filtersComparison}
										isTotalCard={filters?.isTotalCard}
										filterListData={filters?.filterListData}
										typeCard={filters?.type}
									/>
								</Stack>
							)}
							{selectedTab === CHART_TAB_BUTTON.TABLE && (
								<Stack direction={"row"} sx={{ mt: 2, height: "99%", width: "100%", maxWidth: "100%" }}>
									<ChartTableList
										data={chartData?.filterPatients || []}
										relation={filters?.relation}
										getFieldNames={getFieldNames}
										pageType={DASHBOARD_FILTER_TYPE.HOSPITAL}
										tableElementRef={tableElementRef}
										questions={questions}
										isOnlyHospitalDashboard={isOnlyHospitalDashboard}
										searchTerm={debouncedSearchTerm}
										handleHighlightedCount={handleHighlightedCount}
										highlightedCountDefaultValue={highlightedCountDefaultValue}
										filterType={filterType}
										selectedHighlightedColumns={selectedHighlightedColumns}
										orderData={{
											orderBy,
											setOrderBy,
											order,
											setOrder
										}}
										activeFacilities={activeFacilities}
									/>
								</Stack>
							)}
							{selectedTab === CHART_TAB_BUTTON.CHART && (
								<Stack direction={"row"} sx={{ mt: 2, height: "90%" }}>
									<CardDetailChart
										totalText="Total Hospital Transfers"
										data={chartData?.filterData || []}
										filters={filters}
										handleChartLabelType={handleChartLabelType}
										censusAverage={censusAverage}
										total={chartData?.filterPatients?.length || 0}
										setQueryFilters={setQueryFilters}
										setQueryFiltersComparison={setQueryFiltersComparison}
										queryFilters={queryFilters}
										queryFiltersComparison={queryFiltersComparison}
										handleClickFilter={handleClickFilter}
										selectedFilter={selectedFilter}
										reloadChartData={reloadChartData}
										isChartTotalButton={isShowPercentage}
										censusSubtitle={`Of Avg. ${getPercentageByType()}`}
										selectedFacility={selectedFacility}
										dbData={dbData}
									/>
								</Stack>
							)}
						</>
					</NoteContainer>
				)}
				<ChartTabLoader loading={loading} />
			</Grid>
		</ChartDialogContainer>
	);
}
