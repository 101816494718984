import api from "./api";
import { filterBodyDate, toSaveDate, toSaveEndDate } from "../../utilis/date-formats";
const API_PREFIX = "api/admission";

const getDateWiseData = async (params) => {
	params.startDate = toSaveDate(params.startDate);
	params.endDate = toSaveEndDate(params.endDate);
	const response = await api.get(`${API_PREFIX}/dashboard-data`, { params });
	return response;
};

const getValidations = async () => {
	const response = await api.get(`api/validation/all`);
	return response;
};

const getCardPatientData = async (body) => {
	const response = await api.post(`${API_PREFIX}/card-patient-data`, body);
	return response;
};

const getAllAdmissionCount = async (params, { signal } = {}) => {
	const paramsCopy = { ...params };
	paramsCopy.startDate = toSaveDate(params.startDate);
	paramsCopy.endDate = toSaveEndDate(params.endDate);

	const config = {
		params: paramsCopy,
		signal, // Pass the AbortController's signal here
	};

	const response = await api.get(`${API_PREFIX}/get-all-count`, config);
	return response;
};

const getAllADTData = async (body, { signal } = {}) => {
	const copiedBody = { ...body };
	copiedBody.startDate = toSaveDate(body.startDate);
	copiedBody.endDate = toSaveEndDate(body.endDate);

	const config = {
		headers: { 'Content-Type': 'application/json' }, // Optional, specify headers if needed
		signal, // Pass the AbortController's signal here
	};

	const response = await api.post(`${API_PREFIX}/get-adt-data`, copiedBody, config);
	return response;
};

const getCardPatientAndChartData = async (body = null) => {
	body = filterBodyDate(body);
	if (body.transferType === "all") {
		delete body["transferType"];
	}
	const response = await api.post(`${API_PREFIX}/card-patient-chart-data`, body);
	return response;
};

const getCardADTPatientChartData = async (body = null) => {
	body = Object.assign({}, body);
	if (body?.filter) {
		let startDate = toSaveDate(body?.filter.startDate);
		let endDate = toSaveEndDate(body?.filter.endDate);
		body.filter = { ...body?.filter, startDate, endDate };
		if (body?.transferType === "all") {
			delete body?.["transferType"];
		}
	}
	const response = await api.post(`${API_PREFIX}/view-transfer-admission-chart`, body);
	return response;
};

const checkHospitalPriorData = async (body) => {
	const response = await api.post(`api/patient/check-hospital-prior-data`, body);
	return response;
};

const checkAdmissionPriorData = async (body) => {
	const response = await api.post(`api/patient/check-admission-prior-data`, body);
	return response;
};

export {
	checkHospitalPriorData,
	getDateWiseData,
	getValidations,
	getCardPatientData,
	getAllAdmissionCount,
	getAllADTData,
	getCardPatientAndChartData,
	getCardADTPatientChartData,
	checkAdmissionPriorData,
};
