import Cleave from "cleave.js/react";
import AutoComplete from "./validation/AutoComplete";
import { useCallback, useState } from "react";
import CloseSelect from "../../../../../assets/svgs/close-select.svg";
import styles from "./AddPatient.module.scss";
import QuestionLabel from "./QuestionLabel";


const BasicInfo = props => {
    const { isError = false, handleAddHighlight } = props;
    const [suffixSelectedIndex, setSuffixSelectedIndex] = useState(props?.allFields?.suffix || "default");
    const showCloseBtn = suffixSelectedIndex !== "default";

    const suffixChangeHandler = e => {
        const index = e.target.value ? e.target.value : e;
        props.setAllFieldsValue("suffix", index);
        setSuffixSelectedIndex(index);
    };

    const resetSuffixSelect = () => {
        props.setAllFieldsValue("suffix", "");
        setSuffixSelectedIndex("default");
    };

    const getIsHighLightInfo = useCallback((fieldName, color = null) => {
        if (color) {
            return props?.allFields?.highlighter?.find(item => item.fieldName === fieldName)?.color;
        }
        return props?.allFields?.highlighter?.find(item => item.fieldName === fieldName);
        // eslint-disable-next-line
    }, [props?.allFields]);

    return (
        <div>
            <form>
                <div className={`m-b-10 inputWrpr`}>
                    <QuestionLabel
                        handleAddHighlight={handleAddHighlight}
                        accessor={"lastName"}
                        allFields={props?.allFields}
                    >
                        <label className={`ffmr fs12 ${isError && !props.allFields.lastName ? "error" : null}`}>
                            Patient Last Na&zwj;me *
                        </label>
                    </QuestionLabel>
                    <AutoComplete
                        value={props.allFields.lastName}
                        field="lastName"
                        setValue={(field, val) => {
                            return props.setAllFieldsValue(field, val);
                        }}
                        isHighLightInfo={getIsHighLightInfo("lastName", true)}
                    />
                </div>
                <div className={`m-b-10 inputWrpr`}>
                    <QuestionLabel
                        handleAddHighlight={handleAddHighlight}
                        accessor={"middleInitial"}
                        allFields={props?.allFields}
                    >
                        <label className={`ffmr fs12`}>Patient Middle initial</label>
                    </QuestionLabel>
                    <input
                        className={`ffml fs16`}
                        //maxLength={1}
                        value={props.allFields.middleInitial}
                        onChange={e => {
                            props.setAllFieldsValue("middleInitial", e.target.value.slice(0, 1).toUpperCase());
                        }}
                        style={{
                            ...(getIsHighLightInfo("middleInitial") ? { border: `1px solid ${getIsHighLightInfo("middleInitial", true)}` } : {})
                        }}
                    />
                </div>
                <div className={`m-b-10 inputWrpr`}>
                    <QuestionLabel
                        handleAddHighlight={handleAddHighlight}
                        accessor={"firstName"}
                        allFields={props?.allFields}
                    >
                        <label className={`ffmr fs12 ${isError && !props.allFields.firstName ? "error" : null}`}>
                            Patient Fi&zwj;rs&zwj;t Na&zwj;me *
                        </label>
                    </QuestionLabel>

                    <AutoComplete
                        value={props.allFields.firstName}
                        field="firstName"
                        setValue={(field, val) => {
                            return props.setAllFieldsValue(field, val);
                        }}
                        isHighLightInfo={getIsHighLightInfo("firstName", true)}
                    />
                </div>
            </form >
            <div className={`m-b-10 inputWrpr`}>
                <QuestionLabel
                    handleAddHighlight={handleAddHighlight}
                    accessor={"suffix"}
                    allFields={props?.allFields}
                >
                    <label className={`ffmr fs12`}>Patient Suffix</label>
                </QuestionLabel>
                <div className={styles.selectWrapper}>
                    {showCloseBtn && (
                        <div className={styles.closeIcon} onClick={resetSuffixSelect}>
                            <CloseSelect />
                        </div>
                    )}
                    <select
                        className={`ffml fs16`}
                        value={suffixSelectedIndex}
                        onChange={suffixChangeHandler}
                        style={{
                            ...(getIsHighLightInfo("suffix") ? { border: `1px solid ${getIsHighLightInfo("suffix", true)}` } : {})
                        }}
                    >
                        <option value="default" style={{ display: "none" }} selected>
                            Select Suffix
                        </option>
                        <option value="Jr">Jr</option>
                        <option value="Sr">Sr</option>
                    </select>
                </div>
            </div>
            <div className={`m-b-20 inputWrpr`}>
                <QuestionLabel
                    handleAddHighlight={handleAddHighlight}
                    accessor={"DOB"}
                    allFields={props?.allFields}
                >
                    <label className={`ffmr fs12 ${isError && !props.allFields.DOB ? "error" : null}`}>
                        Patient DOB * <span style={{ fontSize: "0.8em", opacity: "0.7" }}>(MM/DD/YYYY)</span>
                    </label>
                </QuestionLabel>
                <Cleave
                    className={`ffml fs16`}
                    value={props.allFields.DOB}
                    onChange={e => props.setAllFieldsValue("DOB", e.target.value)}
                    placeholder="MM/DD/YYYY"
                    options={{
                        date: true,
                        datePattern: ["m", "d", "Y"],
                    }}
                    style={{
                        ...(getIsHighLightInfo("DOB") ? { border: `1px solid ${getIsHighLightInfo("DOB", true)}` } : {})
                    }}
                />
            </div>
            {/* <div className={`m-b-10 inputWrpr`}>
                <LoadingButton
                variant="contained"
                loading={props.loading}
                size="medium"
                onClick={props.handleValidate}
                sx={{alignItems: "center", left:"35%"}}
                >Validate</LoadingButton>
            </div> */}
        </div >
    );
};

export default BasicInfo;
