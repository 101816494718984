import React, { useCallback, useMemo } from "react";
import { Button as MUIButton, Collapse, Typography } from "@mui/material";
import styles from "./AddPatient.module.scss";
import { isOnlyHospitalTabAccess } from "../../../../../utilis/common";
import { ADT_TYPES, ADT_VALIDATION_TYPES } from "../../../../../types/common.type";

const SelectTypeNew = ({
    allFields,
    setAllFieldsValue,
    gotToDetails,
    disabledTypes,
}) => {
    const { validationType } = allFields;

    const isOnlyHospitalDashboard = useMemo(() => isOnlyHospitalTabAccess(), []);

    const types = useMemo(() => {
        if (!isOnlyHospitalDashboard) {
            switch (validationType) {
                case ADT_VALIDATION_TYPES.ALL:
                    return [
                        { label: "Admission", value: "admission" },
                        // { label: "Readmission", value: "readmission" },
                        { label: "Hospital Return", value: "return" },
                        { label: "Transfer", value: "transfer" },
                    ];
                case ADT_VALIDATION_TYPES.ADMISSIONS:
                    return [
                        { label: "Admission", value: "admission" },
                    ];
                case ADT_VALIDATION_TYPES.INCOMING:
                    return [
                        { label: "Admission", value: "admission" },
                        { label: "Hospital Return", value: "return" },
                    ];
                case ADT_VALIDATION_TYPES.READMISSIONS_RETURN:
                    return [
                        { label: "Hospital Return", value: "return" },
                    ];
                case ADT_VALIDATION_TYPES.READMISSIONS:
                    return [
                        { label: "Readmission", value: "readmission" },
                    ];
                case ADT_VALIDATION_TYPES.RETURN:
                    return [{ label: "Hospital Return", value: "return" }];
                case ADT_VALIDATION_TYPES.OUTGOING:
                    return [{ label: "Transfer", value: "transfer" }];
                default:
                    return [];
            }
        } else {
            return [
                { label: "Hospital Return", value: "return" },
                { label: "Transfer", value: "transfer" },
            ];
        }
    }, [isOnlyHospitalDashboard, validationType]);

    const transferTypes = useMemo(
        () => [
            { label: "Hospital Transfer", value: "hospitalTransfer" },
            { label: "Safe Discharge", value: "safeDischarge" },
            { label: "SNF", value: "SNF" },
            { label: "AMA", value: "AMA" },
            { label: "Deceased", value: "deceased" },
        ].filter((type) =>
            isOnlyHospitalDashboard
                ? type.value === "hospitalTransfer"
                : true
        ),
        [isOnlyHospitalDashboard]
    );

    const handleOnClickButton = useCallback((type, value) => {
        if (allFields.type === "transfer" && value === "transfer") {
            setAllFieldsValue("type", null)
            setAllFieldsValue("transferType", null)
        } else {
            setAllFieldsValue(type, value)
        }
    }, [allFields.type, setAllFieldsValue]);

    const renderTransferTypes = () => (
        <div className={`inputWrpr`}>
            <label className={`ffmr fs12`}>Transfer Type</label>
            <div className={`df ffc ffml fs14 ${styles.toggleWrpr} ${styles.vertical}`}>
                {transferTypes.map((type) => (
                    <MUIButton
                        key={type.value}
                        style={{ userSelect: "none" }}
                        className={`${styles.sec} ${allFields.transferType === type.value ||
                            (type.value === "hospitalTransfer" &&
                                ["unplannedHospitalTransfer", "plannedHospitalTransfer"].includes(allFields.transferType))
                            ? styles.selected
                            : ""
                            }`}
                        onClick={() => handleOnClickButton("transferType", type.value)}
                        onDoubleClick={gotToDetails}
                        size="small"
                        fullWidth
                        sx={{ color: "inherit", fontSize: "inherit", fontFamily: "inherit", fontWeight: "inherit" }}
                    >
                        {type.label}
                    </MUIButton>
                ))}
            </div>
        </div>
    );
    const getAdmissionReadmissionSelect = (selectedValue, value) => {
        if(value === ADT_TYPES.ADMISSIONS || value === ADT_TYPES.READMISSIONS) {
            return selectedValue === ADT_TYPES.ADMISSIONS || selectedValue === ADT_TYPES.READMISSIONS;            
        }
        return false;
    }

    const renderTypeButton = (type) => {
        return (
            <MUIButton
                key={type.value}
                style={{ userSelect: "none" }}
                fullWidth
                className={`ffmr fs16 tac ${styles.selectionButton} ${type.value === "admissionOrReadmission" &&
                    ["admission", "readmission"].includes(allFields.type)
                    ? styles.selected
                    : ""
                    } ${(allFields.type === type.value || getAdmissionReadmissionSelect(allFields.type, type.value)) ? styles.selected : ""} ${disabledTypes?.includes(type.value) ? styles.disabled : ""
                    }`}
                onClick={() => {
                    if (disabledTypes?.includes(type.value)) return;
                    handleOnClickButton("type", type.value);
                }}
                onDoubleClick={() => {
                    if (disabledTypes?.includes(type.value)) return;
                    if (type.value !== "transfer") {
                        gotToDetails();
                    }
                }}
                size="small"
                sx={{ color: "inherit" }}
            >
                {type.label}
            </MUIButton>
        )
    };
    if (allFields.validationType === ADT_VALIDATION_TYPES.DECEASED) {
        return (
            <div className={`p-r-20 p-l-20 df ffc ${styles.stepOne}`}>
                <Typography sx={{
                    mt: 4,
                    fontSize: "14px",
                    fontFamily: "mont reg"
                }}>
                    Adding an ADT for this Resident has been disabled because prior data shows the resident is deceased.
                    <br />
                    To continue adding an ADT for this resident please edit prior data.
                </Typography>
            </div>
        )
    }
    return (
        <div className={`p-r-20 p-l-20 df ffc ${styles.stepOne}`}>
            {types.map((thisType) => (
                // Show only the Transfer button when allFields.type === "transfer"
                (allFields.type === "transfer" && thisType.value === "transfer") ? (
                    <>
                        {renderTypeButton(thisType)}
                        <Collapse unmountOnExit mountOnEnter in={allFields.type === "transfer"} timeout={800}>
                            {renderTransferTypes()}
                        </Collapse>
                    </>
                ) : (
                    // Show all buttons when allFields.type is not "transfer"
                    <Collapse unmountOnExit mountOnEnter in={allFields.type !== "transfer"} timeout={800}>
                        {renderTypeButton(thisType)}
                    </Collapse>
                )
            ))}
        </div>
    );
};

export default SelectTypeNew;
