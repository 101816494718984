export const DECEASED_CARDS_TYPE = {
    INSURANCE_DATA: 'insuranceData',
    DOCTOR_DATA: 'doctorData',
    FLOORS_DATA: 'floorsData',
    NINETY_DAYS_DATA: 'ninetyDaysData',
    TOTAL: "total",    
}


export const DECEASED_CARDS_LABELS = {
    insuranceData: 'Per Insurance',
    doctorData: 'Per Doctor',
    floorsData: 'Per Floor',
    ninetyDaysData: '90 Days Analysis',
    total: "Total"
}