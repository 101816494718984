/* eslint-disable no-new-object */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { Box, Grid } from "@mui/material";
import update from "immutability-helper";
import _, { set } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../assets/styles/Hospital.module.scss";
import AdmissionADTDetailsDialog from "../components/dashboard/admission/AdmissionADTDetailsDialog";
import AdmissionCardDetailsDialog from "../components/dashboard/admission/AdmissionCardDetailsDialog";
import AdmissionHeader from "../components/dashboard/admission/AdmissionHeader";
import DxCardList from "../components/dashboard/admission/dxCardList/DxCardList";
import HospitalList from "../components/dashboard/admission/hospitalList/HospitalList";
import TransferADTCardListTable from "../components/dashboard/admission/viewTransferStatusOfAdmission/transferADTCardListTable/TransferADTCardListTable";
import ViewTransferStatusOfAdmission from "../components/dashboard/admission/viewTransferStatusOfAdmission/ViewTransferStatusOfAdmission";
import Card from "../components/dashboard/card/Card";
import dashboardStyles from "../components/dashboard/Dashboard.module.scss";
import DoctorCard from "../components/dashboard/doctorCard/DoctorCard";
import DayCardList from "../components/dashboard/hospital/dayCardList/DayCardList";
import FloorCardList from "../components/dashboard/hospital/floorCardList/FloorCardList";
import HospitalRowContainer from "../components/dashboard/hospital/HospitalRowContainer";
import TotalCountCircle from "../components/dashboard/hospital/totalCountCircle/TotalCountCircle";
import TotalTransferCard from "../components/dashboard/hospital/totalTransferCard/TotalTransferCard";
import InsuranceCard from "../components/dashboard/InsuranceCard/InsuranceCard";
import SelectableCard from "../components/dashboard/selectableCard/SelectableCard";
import TaggedList from "../components/dashboard/shared/taggedList/TaggedList";
import usePdfReport from "../components/hooks/usePdfReport";
import Page from "../components/Page";
import ADTCard from "../components/shared/ADTCard/ADTCard";
import ColorInfoBox from "../components/shared/dashboard/ColorInfoBox";
import PickProjectionDaysPopup from "../components/shared/PickProjectionDaysPopup/PickProjectionDaysPopup";
import { ADMISSION_TYPES } from "../data/admission.data";
import { allADTData, getAllAdmissionsPatient } from "../services/admission.service";
import {
	DEFAULT_CARD_FILTER,
	openADTDetailsDialog,
	openDetailsDialog,
	setCardFilter,
	setDefaultADTData,
	setIsAdtLoading,
	setLockedTotalBy,
	setLockFilterTotalState,
	setLockTotal,
	setMainTotal,
	setReversedColorAdmission,
	setSelectedADTTableData,
	setTransferType,
} from "../store/reducers/admission.slice";
import {
	openADTDetailsDialog as openADTDetailsDialogComparison,
	openDetailsDialog as openDetailsDialogComparison,
	setCardFilter as setCardFilterComparison,
	setDefaultADTData as setDefaultADTDataComparison,
	setFilterListData as setFilterListDataComparison,
	setLockFilterTotalState as setLockFilterTotalStateComparison,
	setMainTotal as setMainTotalComparison,
	setPatientList as setPatientListComparison,
	setSelectedADTTableData as setSetSelectedADTTableDataComparison,
	setTransferType as setTransferTypeComparison,
} from "../store/reducers/comparisonReducers/admissionComparison.slice";
import { ADD_NOTIFICATION } from "../store/types";
import { ADMISSION_CARDS_TYPE, ADMISSION_FILTER_TYPE } from "../types/admission.type";
import { PATIENT_RELATION } from "../types/chart-dialog.type";
import { ADT_TABLE_TYPE } from "../types/common.type";
import { PAGE_TYPE } from "../types/pages.type";
import {
	adtCardFilterParam,
	getADTDataDefault,
	updateAdmissionChartFilter,
	updateADTChartFilter,
	updateFilterListDataBoth,
} from "../utilis/admission-common";
import { admissionPriorityFilter, getDynamicPercentageBy, itemPercentage, projectionPerMonth } from "../utilis/common";
import { toSaveDate, toSaveEndDate } from "../utilis/date-formats";
import tooltipsInfo from "../utilis/tooltipContents";
import useCustomCardFilter from "../components/hooks/useCustomCardFilter";
import { setAdmissionCards } from "../store/reducers/customCard.slice";
import AddCardDrawer from "../components/shared/add-filter-card/add-card-drawer";
import { ADMISSION_CUSTOM_CARD } from "../types/custom-card-type";
import CardAnimation from "../components/shared/CardAnimation";
import ShadowLineWithText from "../components/shared/ShadowLineWithText";
import { setRefetchComparison } from "../store/reducers/comparisonReducers/comparison.slice";
import NoteContainer from "../components/shared/NoteContainer";

const DEFAULT_LIST = {
	list: [],
	adtList: [],
};

const AdmissionPage = () => {
	const { downloadPdf, saveAutomaticReport, downloadExcel } = usePdfReport();
	const dispatch = useDispatch();
	const [patientList, setPatientList] = useState(DEFAULT_LIST);
	const [isDateUpdated, setIsDateUpdated] = useState(false);
	const { rangesSet, refetchComparison } = useSelector((state) => state.comparison);
	const [filterListData, setFilterListData] = useState(DEFAULT_CARD_FILTER);
	const { lastADTDate, projectionDays } = useSelector((state) => state.common);
	const { admissionCards } = useSelector((state) => state.customCard);
	const { saveCustomCardFilter } = useCustomCardFilter();
	const {
		filter,
		dbData,
		transferType,
		cardFilter,
		dbSelectedFilters,
		selectedADTTableData,
		detailsDialog,
		detailsADTDialog,
		defaultADTData,
		isAdtLoading,
		mainNumPercentage,
		lockedTotal,
		adtFacilityPercentage,
		isCensusTotalLocked,
		lockedByFacility,
		lockedTotalBy,
		lockeByADT,
		lockFilterTotalState,
		reversedColorAdmission,
		filterTotal,
	} = useSelector((state) => state.admission);
	const { percentageBy } = useSelector((state) => state.common);

	const percentageAgainst = useMemo(() => {
		return getDynamicPercentageBy(dbData, percentageBy);
	}, [percentageBy, dbData]);

	const {
		filterListData: filterListDataComparison,
		dbData: dbDataComparison,
		filter: filterComparison,
		dbSelectedFilters: dbSelectedFiltersComparison,
		cardFilter: cardFilterComparison,
		patientList: patientListComparison,
		transferType: transferTypeComparison,
		selectedADTTableData: selectedADTTableDataComparison,
		defaultADTData: defaultADTDataComparison,
		lockFilterTotalState: lockFilterTotalStateComparison,
		lockedTotal: lockedTotalComparison,
		lockedByFacility: lockedByFacilityComparison,
		isCensusTotalLocked: isCensusTotalLockedComparison,
		adtFacilityPercentage: adtFacilityPercentageComparison,
	} = useSelector((state) => state.admissionComparison);
	const { percentageThresholds, comparingAgainstDifferentFacility } = useSelector((state) => state.comparison);
	const [disableTotalHospitalizationsCard, setDisableTotalHospitalizationsCard] = useState(false);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const activeFacilitiesComparison = useSelector((state) => state.comparison.activeComparisonFacilitiesId);
	const handleOnclickCard = useCallback(
		(value, total, mainTotal, totalComparison, mainTotalComparison) => {
			if (transferType && transferType === value) {
				dispatch(setTransferType(null));
				dispatch(setMainTotal(mainTotal));

				dispatch(setTransferTypeComparison(null));
				dispatch(setMainTotalComparison(mainTotalComparison));
			} else {
				dispatch(setTransferType(value));
				dispatch(setMainTotal(total));

				dispatch(setTransferTypeComparison(value));
				dispatch(setMainTotalComparison(totalComparison));
			}
		},
		[dispatch, transferType]
	);
	const activeFacilityLocalStorage = localStorage.getItem("facilityId");
	const [projectionPopupOpen, setProjectionPopupOpen] = useState(false);

	// loading states
	const [loading, setLoading] = useState(true);
	const [loadingComparison, setLoadingComparison] = useState(true);

	useEffect(() => {
		const getAllData = async (filter) => {
			dispatch(setLockTotal(null));
			dispatch(setLockedTotalBy(null));
			getDashboardData(filter)			
		};
		if (
			(isDateUpdated || refetchComparison) &&
			filter &&
			filter.startDate &&
			localStorage.getItem("facilityId") &&
			activeFacilities.length > 0
		) {
			getAllData({ ...filter, facilityIds: activeFacilities });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, filterComparison, activeFacilityLocalStorage]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilter(patientListComparison, undefined, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mainNumPercentage, lockedTotal, patientList, rangesSet, projectionDays, percentageAgainst]);

	useEffect(() => {
		updateTotalToDBFilter(patientList);
		rangesSet && updateTotalToDBFilter(patientListComparison, undefined, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patientList, patientListComparison, rangesSet, transferType, projectionDays]);

	const reloadAdmissionFilter = useCallback(
		async (latestPriorityData, latestDefaultADTData) => {
			const dataFilters = await updateFilterListDataBoth(
				patientList,
				cardFilter,
				transferType,
				latestPriorityData,
				latestDefaultADTData,
				false,
				projectionDays,
				filter
			);
			let latestPatientList = dataFilters.patientList;
			setFilterListData((prevState) => ({
				...prevState,
				...latestPatientList,
			}));
			dispatch(setSelectedADTTableData(dataFilters.adtList));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[patientList, cardFilter, transferType, selectedADTTableData, dispatch, projectionDays]
	);

	const addTotalHospitalizationsToADTData = (adtData) => {
		const updatedAdtData = [...adtData];
		let isSelectedChanged = false;

		const hospitalTransferIndex = updatedAdtData.findIndex((item) => item.id === "hospital");

		if (hospitalTransferIndex !== -1) {
			const hospitalTransfer = { ...updatedAdtData[hospitalTransferIndex] };

			const allIndex = hospitalTransfer.children.findIndex((item) => item.id === "all");

			if (allIndex !== -1) {
				isSelectedChanged = !hospitalTransfer.children[allIndex].isSelected;
				const updatedAll = { ...hospitalTransfer.children[allIndex], isSelected: true };

				hospitalTransfer.children = [
					...hospitalTransfer.children.slice(0, allIndex),
					updatedAll,
					...hospitalTransfer.children.slice(allIndex + 1),
				];

				updatedAdtData[hospitalTransferIndex] = hospitalTransfer;
			}
		}

		return { updatedAdtData, isSelectedChanged };
	};

	const updateTotalToDBFilter = useCallback(
		async (res, defaultADTDataRes, forComparison) => {
			let defaultADTDataResData = defaultADTDataRes
				? defaultADTDataRes
				: forComparison
					? defaultADTDataComparison
					: defaultADTData;

			const dataFilters = await updateFilterListDataBoth(
				res,
				forComparison ? cardFilterComparison : cardFilter,
				transferType,
				forComparison ? cardFilterComparison?.mainPriorityData : cardFilter.mainPriorityData,
				defaultADTDataResData,
				forComparison
			);
			let latestPatientList = dataFilters.patientList;
			forComparison
				? dispatch(setFilterListDataComparison({ ...filterListDataComparison, ...latestPatientList }))
				: setFilterListData((prevState) => ({
					...prevState,
					...latestPatientList,
				}));
			dispatch(
				forComparison
					? setSetSelectedADTTableDataComparison(dataFilters.adtList)
					: setSelectedADTTableData(dataFilters.adtList)
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cardFilterComparison, cardFilter, transferType, defaultADTDataComparison, defaultADTData, dispatch, projectionDays]
	);

	const getAllADTData = async (filter, { forComparison = false }, updatedPatientListNew) => {
		dispatch(setIsAdtLoading(true));
		allADTData(filter, forComparison ? "comparison" : "main")
			.then(async (res) => {
				dispatch(setIsAdtLoading(false));
				if (res && res.list) {
					if (forComparison) {
						const defaultListFilterData = await getADTDataDefault(res, cardFilterComparison.adtData, forComparison);
						dispatch(setDefaultADTDataComparison(defaultListFilterData));
						let updatedPatientListLatest = {
							...updatedPatientListNew,
							adtList: res,
						};
						dispatch(setPatientListComparison(updatedPatientListLatest));
						await updateTotalToDBFilter(updatedPatientListLatest, defaultListFilterData, forComparison);
					} else {
						const defaultListFilterData = await getADTDataDefault(res, cardFilter.adtData);
						dispatch(setDefaultADTData(defaultListFilterData));
						let updatedPatientListLatest = {
							...updatedPatientListNew,
							adtList: res,
						};
						setPatientList(updatedPatientListLatest);
						await updateTotalToDBFilter(updatedPatientListLatest, defaultListFilterData);
					}
				}
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const defaultFilterData = (forComparison) => {
		if (forComparison) {
			dispatch(setPatientListComparison(DEFAULT_LIST));
			setFilterListDataComparison(DEFAULT_CARD_FILTER);
		} else {
			setPatientList(DEFAULT_LIST);
			setFilterListData(DEFAULT_CARD_FILTER);
		}
	}

	const getDashboardData = async (filter) => {
		setLoading(true);
		setLoadingComparison(true);
		console.log("calling");
		await Promise.all([
			getAllAdmissionsPatient(filter, false, "main").then(async (res) => {
				if (res && res.list.length > 0) {
					await updateTotalToDBFilter({ ...patientList, list: res.list });
					const updatedPatientList = {
						...patientList,
						list: res.list,
					};
					setPatientList({ ...updatedPatientList })
					if (cardFilter && cardFilter.adtData.length > 0) {
						await handleOnClickRun(updatedPatientList, false);
					}
				} else {
					defaultFilterData(false)
				}
			}),
			rangesSet ? await getAllAdmissionsPatient(filterComparison, true, "comparisonFromDialog").then(async (res) => {
				if (res && res.list.length > 0) {
					const defaultListFilterData = await getADTDataDefault(res, cardFilter.adtData, true);
					await updateTotalToDBFilter({ ...patientListComparison, list: res.list }, defaultListFilterData, true);
					const updatedPatientList = {
						...patientListComparison,
						list: res.list,
					};
					dispatch(setPatientListComparison({ ...updatedPatientList }))
					if (cardFilter && cardFilter.adtData.length > 0) {
						await handleOnClickRun(updatedPatientList, true);
					}
				} else {
					defaultFilterData(true)
				}
			}) : defaultFilterData(true),
		]).catch((e) => {
			console.log(e?.message, 'e?.message');

			if (e?.message !== "canceled") {
				setLoading(false);
				setLoadingComparison(false);
				dispatch(setRefetchComparison(false));
			}
		}).finally(() => {
			console.log("finally");
			setLoading(false);
			setLoadingComparison(false);
			dispatch(setRefetchComparison(false));
		});
	};

	const handleToggle = useCallback(
		async ({ item, type, cardTitle }) => {
			if (item) {
				const value = item._id;
				const filtersItem = cardFilter;
				const filterData = filtersItem[type];
				const currentIndex = filterData?.indexOf(value);
				const newChecked = filterData ? [...filterData] : [];

				if (currentIndex === -1) {
					newChecked.push(value);
				} else {
					newChecked.splice(currentIndex, 1);
				}

				const [mainPriorityData, mainPriorityDataComparison] = await Promise.all([
					admissionPriorityFilter(
						cardFilter.mainPriorityData,
						{ type },
						newChecked.length,
						ADMISSION_FILTER_TYPE.ADMISSION,
						cardTitle
					),
					rangesSet
						? admissionPriorityFilter(
							cardFilterComparison?.mainPriorityData,
							{ type },
							newChecked.length,
							ADMISSION_FILTER_TYPE.ADMISSION,
							cardTitle
						)
						: undefined,
				]);

				const [dataFilters, dataFiltersComparison] = await Promise.all([
					updateFilterListDataBoth(
						patientList,
						{ ...cardFilter, [type]: newChecked },
						transferType,
						mainPriorityData,
						defaultADTData,
						false
					),
					rangesSet
						? updateFilterListDataBoth(
							patientListComparison,
							{ ...cardFilterComparison, [type]: newChecked },
							transferTypeComparison,
							mainPriorityDataComparison,
							defaultADTDataComparison,
							true
						)
						: undefined,
				]);

				const latestPatientList = dataFilters.patientList;
				const latestPatientListComparison = dataFiltersComparison?.patientList;

				setFilterListData((prevState) => {
					return {
						...prevState,
						...latestPatientList,
					};
				});

				if (rangesSet) {
					const updatedFilterListData = { ...filterListDataComparison, ...latestPatientListComparison };
					dispatch(setFilterListDataComparison(updatedFilterListData));
				}

				dispatch(setSelectedADTTableData(dataFilters.adtList));
				rangesSet && dispatch(setSetSelectedADTTableDataComparison(dataFiltersComparison?.adtList));

				dispatch(
					setCardFilter({
						[type]: newChecked,
						mainPriorityData: mainPriorityData,
					})
				);
				rangesSet &&
					dispatch(
						setCardFilterComparison({
							[type]: newChecked,
							mainPriorityData: mainPriorityDataComparison,
						})
					);
			}
		},
		[
			cardFilter,
			rangesSet,
			cardFilterComparison,
			patientList,
			transferType,
			defaultADTData,
			patientListComparison,
			transferTypeComparison,
			defaultADTDataComparison,
			dispatch,
			filterListDataComparison,
		]
	);

	const handleToggleAll = async ({ items, type, cardTitle = null }) => {
		const filtersItem = cardFilter;
		const filterData = filtersItem[type];
		const itemIds = items.map((x) => x._id);
		const valuesAlreadyInFilterData = itemIds.every((x) => filterData.includes(x));
		let newChecked = [];
		if (valuesAlreadyInFilterData) {
			newChecked = filterData.filter((x) => !itemIds.some((y) => y === x));
		} else {
			newChecked = [...filterData, ...itemIds];
		}

		const [mainPriorityData, mainPriorityDataComparison] = await Promise.all([
			admissionPriorityFilter(
				cardFilter.mainPriorityData,
				{ type },
				newChecked.length,
				ADMISSION_FILTER_TYPE.ADMISSION,
				cardTitle
			),
			rangesSet
				? admissionPriorityFilter(
					cardFilterComparison?.mainPriorityData,
					{ type },
					newChecked.length,
					ADMISSION_FILTER_TYPE.ADMISSION,
					cardTitle
				)
				: undefined,
		]);

		const [dataFilters, dataFiltersComparison] = await Promise.all([
			updateFilterListDataBoth(
				patientList,
				{ ...cardFilter, [type]: newChecked },
				transferType,
				mainPriorityData,
				defaultADTData,
				false
			),
			rangesSet
				? updateFilterListDataBoth(
					patientListComparison,
					{ ...cardFilterComparison, [type]: newChecked },
					transferTypeComparison,
					mainPriorityDataComparison,
					defaultADTDataComparison,
					true
				)
				: undefined,
		]);

		const latestPatientList = dataFilters.patientList;
		const latestPatientListComparison = dataFiltersComparison?.patientList;

		setFilterListData((prevState) => {
			return {
				...prevState,
				...latestPatientList,
			};
		});

		if (rangesSet) {
			const updatedFilterListData = { ...filterListDataComparison, ...latestPatientListComparison };
			dispatch(setFilterListDataComparison(updatedFilterListData));
		}

		dispatch(setSelectedADTTableData(dataFilters.adtList));
		rangesSet && dispatch(setSetSelectedADTTableDataComparison(dataFiltersComparison?.adtList));

		dispatch(
			setCardFilter({
				[type]: newChecked,
				mainPriorityData: mainPriorityData,
			})
		);
		rangesSet &&
			dispatch(
				setCardFilterComparison({
					[type]: newChecked,
					mainPriorityData: mainPriorityDataComparison,
				})
			);
	};

	const handleToggleADTTab = async ({
		savedData,
		savedDataComparison,
		selectedTransferIds,
		selectedTransferIdsComparison,
		item,
		itemComparison = null,
		parentItem,
		parentItemComparison = {},
		total,
		selectedIds,
		isChecked,
	}) => {
		let adtAdmitPatientIds = cardFilter.adtAdmitPatientIds.length > 0 ? cardFilter.adtAdmitPatientIds : [];
		let adtAdmitPatientIdsComparison =
			cardFilterComparison.adtAdmitPatientIds.length > 0 ? cardFilterComparison.adtAdmitPatientIds : [];

		const { childId, cardId, subTitle, relation } = parentItem;
		const {
			childId: childIdComparison,
			cardId: cardIdComparison,
			subTitle: subTitleComparison,
			relation: relationComparison,
		} = parentItemComparison;
		const { type, element } = item;
		let typeComparison;
		let elementComparison;
		if (itemComparison) {
			typeComparison = itemComparison?.typeComparison;
			elementComparison = itemComparison?.elementComparison;
		}
		//const { type: typeComparison, element: elementComparison } = itemComparison;
		const selectedData = _.find(adtAdmitPatientIds, {
			id: childId,
			cardId: cardId,
		});
		const selectedDataComparison = _.find(adtAdmitPatientIdsComparison, {
			id: childIdComparison,
			cardId: cardIdComparison,
		});
		let latestAdmitFilters = [];
		let latestAdmitFiltersComparison = [];

		if (selectedData) {
			if (savedData.length > 0) {
				latestAdmitFilters = update(adtAdmitPatientIds, {
					$set: adtAdmitPatientIds.map((elem) => {
						if (elem.id === childId) {
							return {
								...elem,
								ids: savedData,
								selectedIds: selectedIds,
								transferIds: selectedTransferIds,
								total,
							};
						} else {
							return elem;
						}
					}),
				});
			} else {
				latestAdmitFilters = await update(adtAdmitPatientIds, {
					$splice: [[adtAdmitPatientIds.indexOf(selectedData), 1]],
				});
			}
		} else {
			let adtFilterObj = new Object();
			adtFilterObj.id = childId;
			adtFilterObj.cardId = cardId;
			adtFilterObj.childId = childId;
			adtFilterObj.label = subTitle;
			adtFilterObj.total = total;
			adtFilterObj.ids = savedData;
			adtFilterObj.transferIds = selectedTransferIds;
			adtFilterObj.type = type;
			adtFilterObj.relation = relation;
			adtFilterObj.selectedIds = selectedIds;
			latestAdmitFilters = await update(adtAdmitPatientIds, {
				$push: [{ ...adtFilterObj, ...element, ...parentItem }],
			});
		}

		if (selectedDataComparison) {
			if (savedDataComparison.length > 0) {
				latestAdmitFiltersComparison = update(adtAdmitPatientIdsComparison, {
					$set: adtAdmitPatientIdsComparison.map((elem) => {
						if (elem.id === childId) {
							return {
								...elem,
								ids: savedDataComparison,
								selectedIds: selectedIds,
								transferIds: selectedTransferIdsComparison,
								total,
							};
						} else {
							return elem;
						}
					}),
				});
			} else {
				latestAdmitFiltersComparison = await update(adtAdmitPatientIdsComparison, {
					$splice: [[adtAdmitPatientIdsComparison.indexOf(selectedDataComparison), 1]],
				});
			}
		} else {
			let adtFilterObjComparison = new Object();
			adtFilterObjComparison.id = childIdComparison;
			adtFilterObjComparison.cardId = cardIdComparison;
			adtFilterObjComparison.childId = childIdComparison;
			adtFilterObjComparison.label = subTitleComparison;
			adtFilterObjComparison.total = total;
			adtFilterObjComparison.ids = savedDataComparison;
			adtFilterObjComparison.transferIds = selectedTransferIdsComparison;
			adtFilterObjComparison.type = typeComparison;
			adtFilterObjComparison.relation = relationComparison;
			adtFilterObjComparison.selectedIds = selectedIds;

			latestAdmitFiltersComparison = await update(adtAdmitPatientIdsComparison, {
				$push: [{ ...adtFilterObjComparison, ...elementComparison, ...parentItemComparison }],
			});
		}

		let mainPriorityData = await admissionPriorityFilter(
			cardFilter.mainPriorityData,
			{ childId, cardId },
			latestAdmitFilters,
			ADMISSION_FILTER_TYPE.ADT
		);

		let mainPriorityDataComparison = await admissionPriorityFilter(
			cardFilterComparison.mainPriorityData,
			{ childId: childIdComparison, cardId: cardIdComparison },
			latestAdmitFiltersComparison,
			ADMISSION_FILTER_TYPE.ADT
		);

		if (!isChecked) {
			const lockedFilterTotal =
				lockFilterTotalState.count === 0 ? dbData?.bedCapacity : lockFilterTotalState.lockedFilterTotal;
			dispatch(setReversedColorAdmission(false));
			const lockedFilterTotalComparison =
				lockFilterTotalStateComparison.count === 0
					? dbDataComparison?.bedCapacity
					: lockFilterTotalStateComparison.lockedFilterTotal;
			dispatch(
				setLockFilterTotalState({
					count: lockFilterTotalState.count + 1,
					isLocked: true,
					lockedFilterTotal,
				})
			);
			dispatch(
				setLockFilterTotalStateComparison({
					count: lockFilterTotalStateComparison.count + 1,
					isLocked: true,
					lockedFilterTotal: lockedFilterTotalComparison,
				})
			);
		} else {
			const newCount = lockFilterTotalState.count - 1;
			const isLocked = newCount !== 0;
			const newCountComparison = lockFilterTotalStateComparison.count - 1;
			const isLockedComparison = newCountComparison !== 0;
			dispatch(setReversedColorAdmission(true));
			dispatch(
				setLockFilterTotalState({
					...lockFilterTotalState,
					count: lockFilterTotalState.count - 1,
					isLocked,
				})
			);
			dispatch(
				setLockFilterTotalStateComparison({
					...lockFilterTotalStateComparison,
					count: lockFilterTotalStateComparison.count - 1,
					isLocked: isLockedComparison,
				})
			);
		}

		const dataFilters = await updateFilterListDataBoth(
			patientList,
			{ ...cardFilter },
			transferType,
			mainPriorityData,
			defaultADTData,
			false,
			projectionDays
		);
		const dataFiltersComparison = await updateFilterListDataBoth(
			patientListComparison,
			{ ...cardFilterComparison },
			transferTypeComparison,
			mainPriorityDataComparison,
			defaultADTDataComparison,
			true,
			projectionDays
		);

		let latestPatientList = dataFilters.patientList;
		let latestPatientListComparison = dataFiltersComparison.patientList;

		dispatch(
			setFilterListDataComparison({
				...filterListDataComparison,
				...latestPatientListComparison,
			})
		);
		setFilterListData((prevState) => ({
			...prevState,
			...latestPatientList,
		}));

		dispatch(setSetSelectedADTTableDataComparison(dataFiltersComparison.adtList));
		dispatch(setSelectedADTTableData(dataFilters.adtList));
		dispatch(
			setCardFilterComparison({
				adtAdmitPatientIds: latestAdmitFiltersComparison,
				mainPriorityData: mainPriorityDataComparison,
			})
		);
		dispatch(
			setCardFilter({
				adtAdmitPatientIds: latestAdmitFilters,
				mainPriorityData: mainPriorityData,
			})
		);
	};

	const handleToggleAllADTTab = async ({
		savedData,
		savedDataComparison,
		selectedTransferIds,
		selectedTransferIdsComparison,
		items,
		itemsComparison = null,
		parentItem,
		parentItemComparison = {},
		total,
		selectedIds,
		isChecked,
		setSelected,
	}) => {
		try {
			let adtAdmitPatientIds = cardFilter.adtAdmitPatientIds.length > 0 ? cardFilter.adtAdmitPatientIds : [];
			let adtAdmitPatientIdsComparison =
				cardFilterComparison.adtAdmitPatientIds.length > 0 ? cardFilterComparison.adtAdmitPatientIds : [];

			const { childId, cardId, subTitle, relation } = parentItem;
			const {
				childId: childIdComparison,
				cardId: cardIdComparison,
				subTitle: subTitleComparison,
				relation: relationComparison,
			} = parentItemComparison;

			const selectedData = _.find(adtAdmitPatientIds, {
				id: childId,
				cardId: cardId,
			});
			const selectedDataComparison = _.find(adtAdmitPatientIdsComparison, {
				id: childIdComparison,
				cardId: cardIdComparison,
			});
			let latestAdmitFilters = [];
			let latestAdmitFiltersComparison = [];

			if (selectedData) {
				if (savedData.length > 0) {
					latestAdmitFilters = !isChecked
						? []
						: update(adtAdmitPatientIds, {
							$set: adtAdmitPatientIds.map((elem) => {
								if (elem.id === childId) {
									return {
										...elem,
										ids: savedData,
										selectedIds: selectedIds,
										transferIds: selectedTransferIds,
										total,
									};
								} else {
									return elem;
								}
							}),
						});
				} else {
					latestAdmitFilters = !isChecked
						? []
						: await update(adtAdmitPatientIds, {
							$splice: [[adtAdmitPatientIds.indexOf(selectedData), 1]],
						});
				}
			} else {
				items.map((item) => {
					let adtFilterObj = {};
					adtFilterObj.id = childId;
					adtFilterObj.cardId = cardId;
					adtFilterObj.childId = childId;
					adtFilterObj.label = subTitle;
					adtFilterObj.total = total;
					adtFilterObj.ids = savedData;
					adtFilterObj.transferIds = selectedTransferIds;
					adtFilterObj.type = item.type;
					adtFilterObj.relation = relation;
					adtFilterObj.selectedIds = selectedIds;

					latestAdmitFilters = update(adtAdmitPatientIds, {
						$push: [{ ...adtFilterObj, ...item.element, ...parentItem }],
					});
				});
			}

			if (selectedDataComparison) {
				if (savedDataComparison.length > 0) {
					latestAdmitFiltersComparison = update(adtAdmitPatientIdsComparison, {
						$set: adtAdmitPatientIdsComparison.map((elem) => {
							if (elem.id === childId) {
								return {
									...elem,
									ids: savedDataComparison,
									selectedIds: selectedIds,
									transferIds: selectedTransferIdsComparison,
									total,
								};
							} else {
								return elem;
							}
						}),
					});
				} else {
					latestAdmitFiltersComparison = await update(adtAdmitPatientIdsComparison, {
						$splice: [[adtAdmitPatientIdsComparison.indexOf(selectedDataComparison), 1]],
					});
				}
			} else {
				itemsComparison.map((itemComparison) => {
					let adtFilterObjComparison = {};
					adtFilterObjComparison.id = childIdComparison;
					adtFilterObjComparison.cardId = cardIdComparison;
					adtFilterObjComparison.childId = childIdComparison;
					adtFilterObjComparison.label = subTitleComparison;
					adtFilterObjComparison.total = total;
					adtFilterObjComparison.ids = savedDataComparison;
					adtFilterObjComparison.transferIds = selectedTransferIdsComparison;
					adtFilterObjComparison.type = itemComparison.type;
					adtFilterObjComparison.relation = relationComparison;
					adtFilterObjComparison.selectedIds = selectedIds;

					latestAdmitFiltersComparison = update(adtAdmitPatientIdsComparison, {
						$push: [{ ...adtFilterObjComparison, ...itemComparison.element, ...parentItemComparison }],
					});
				});
			}

			let mainPriorityData = await admissionPriorityFilter(
				cardFilter.mainPriorityData,
				{ childId, cardId },
				latestAdmitFilters,
				ADMISSION_FILTER_TYPE.ADT
			);

			let mainPriorityDataComparison = await admissionPriorityFilter(
				cardFilterComparison.mainPriorityData,
				{ childId: childIdComparison, cardId: cardIdComparison },
				latestAdmitFiltersComparison,
				ADMISSION_FILTER_TYPE.ADT
			);

			if (!isChecked) {
				const lockedFilterTotal =
					lockFilterTotalState.count === 0 ? dbData?.bedCapacity : lockFilterTotalState.lockedFilterTotal;
				dispatch(setReversedColorAdmission(false));
				const lockedFilterTotalComparison =
					lockFilterTotalStateComparison.count === 0
						? dbDataComparison?.bedCapacity
						: lockFilterTotalStateComparison.lockedFilterTotal;
				dispatch(
					setLockFilterTotalState({
						count: lockFilterTotalState.count + 1,
						isLocked: true,
						lockedFilterTotal,
					})
				);
				dispatch(
					setLockFilterTotalStateComparison({
						count: lockFilterTotalStateComparison.count + 1,
						isLocked: true,
						lockedFilterTotal: lockedFilterTotalComparison,
					})
				);
			} else {
				const newCount = lockFilterTotalState.count - 1;
				const isLocked = newCount !== 0;
				const newCountComparison = lockFilterTotalStateComparison.count - 1;
				const isLockedComparison = newCountComparison !== 0;
				dispatch(setReversedColorAdmission(true));
				dispatch(
					setLockFilterTotalState({
						...lockFilterTotalState,
						count: lockFilterTotalState.count - 1,
						isLocked,
					})
				);
				dispatch(
					setLockFilterTotalStateComparison({
						...lockFilterTotalStateComparison,
						count: lockFilterTotalStateComparison.count - 1,
						isLocked: isLockedComparison,
					})
				);
			}

			const dataFilters = await updateFilterListDataBoth(
				patientList,
				{ ...cardFilter },
				transferType,
				mainPriorityData,
				defaultADTData,
				false,
				projectionDays
			);
			const dataFiltersComparison = await updateFilterListDataBoth(
				patientListComparison,
				{ ...cardFilterComparison },
				transferTypeComparison,
				mainPriorityDataComparison,
				defaultADTDataComparison,
				true,
				projectionDays
			);

			let latestPatientList = dataFilters.patientList;
			let latestPatientListComparison = dataFiltersComparison.patientList;

			dispatch(
				setFilterListDataComparison({
					...filterListDataComparison,
					...latestPatientListComparison,
				})
			);
			setFilterListData((prevState) => ({
				...prevState,
				...latestPatientList,
			}));

			dispatch(setSetSelectedADTTableDataComparison(dataFiltersComparison.adtList));
			dispatch(setSelectedADTTableData(dataFilters.adtList));
			dispatch(
				setCardFilterComparison({
					adtAdmitPatientIds: latestAdmitFiltersComparison,
					mainPriorityData: mainPriorityDataComparison,
				})
			);
			dispatch(
				setCardFilter({
					adtAdmitPatientIds: latestAdmitFilters,
					mainPriorityData: mainPriorityData,
				})
			);
		} catch (e) {
			console.log(e);
		}
	};

	const handleClickTotal = useCallback(
		(total, totalComparison) => {
			dispatch(setTransferType(transferType === ADMISSION_TYPES.ALL ? null : ADMISSION_TYPES.ALL));
			dispatch(setTransferTypeComparison(transferType === ADMISSION_TYPES.ALL ? null : ADMISSION_TYPES.ALL));
			dispatch(setMainTotal(total));
			dispatch(setMainTotalComparison(totalComparison));
		},
		[dispatch, transferType]
	);

	const handleOnClickRun = useCallback(
		async (updatedPatientList, forComparison) => {
			let isSelectedChildData = false;
			cardFilter.adtData.map((item) => {
				const isChildSelected = _.find(item.children, { isSelected: true });
				if (isChildSelected) {
					isSelectedChildData = true;
				}
			});

			if (isSelectedChildData) {
				const adtFilters = await adtCardFilterParam(cardFilter.adtData);
				const adtFiltersComparison = await adtCardFilterParam(cardFilterComparison?.adtData);
				if (forComparison) {
					let updatedPatientListNew = updatedPatientList ? updatedPatientList : patientListComparison;
					await getAllADTData(
						{ facilityIds: activeFacilities, ...adtFilters, ...filterComparison },
						{
							...filterComparison,
							forComparison,
							facilityIds: activeFacilities,
							...adtFiltersComparison,
						},
						updatedPatientListNew
					);
				} else {
					let updatedPatientListNew = updatedPatientList ? updatedPatientList : patientList;
					await getAllADTData(
						{ ...filter, facilityIds: activeFacilities, ...adtFilters },
						{
							...filterComparison,
							forComparison,
							facilityIds: activeFacilities,
							...adtFiltersComparison,
						},
						updatedPatientListNew
					);
				}
			} else {
				dispatch({
					type: ADD_NOTIFICATION,
					payload: {
						type: "error",
						label: `Please select at least one option`,
						id: "admissionPleaseSelectOne",
					},
				});
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cardFilter.adtData, dispatch, filter, activeFacilities, filterComparison, patientListComparison]
	);

	const handleADTTabChartDialog = useCallback(
		async (selectedCardItem, type = null) => {
			let latestCardFilter = await updateADTChartFilter(cardFilter, selectedCardItem);
			let selectedFilterData = [];
			let data;
			if (selectedCardItem?.type === ADT_TABLE_TYPE.ALL || selectedCardItem?.type === ADT_TABLE_TYPE.TOTAL) {
				data = [];
				selectedFilterData = [];
			} else {
				data = selectedCardItem?.tableData || [];
				selectedFilterData = selectedCardItem?.selectedIds;
			}
			let patientFilterData = patientList.list;
			if (transferType === ADMISSION_TYPES.ADMISSION || transferType === ADMISSION_TYPES.READMISSION) {
				patientFilterData = _.filter(patientList.list, {
					type: transferType,
				});
			}
			let filterTotalByTransferType = _(patientFilterData)
				.groupBy("facilityId")
				.sortBy((group) => patientFilterData.indexOf(group[0]))
				.map((product) => {
					return {
						id: product[0].facilityId || null,
						total: product.length || 0,
					};
				})
				.value();
			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				selectedFilterData,
				type: selectedCardItem,
				filter,
				transferType,
				selectedCardItem,
				relation: selectedCardItem?.relation || null,
				mainPriorityData: cardFilter.mainPriorityData,
				filterTotalByTransferType: filterTotalByTransferType,
				filterSelected:
					cardFilter.mainPriorityData.length > 0 && cardFilter.mainPriorityData[0]["type"] !== cardFilter.type,
				adtFacilityPercentage: adtFacilityPercentage,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked: false,
				filterListData,
				selectedADTTableData
			};
			dispatch(
				openADTDetailsDialog({
					isOpen: true,
					selectedCardItem,
					type,
					data,
					filters,
					admissionTotal: filterListData?.admissionTotal || 0,
					dbData,
				})
			);

			// COMPARISON BLOCK
			let latestCardFilterComparison = await updateADTChartFilter(cardFilterComparison, selectedCardItem);
			let selectedFilterDataComparison = [];
			let dataComparison;
			if (selectedCardItem?.type === ADT_TABLE_TYPE.ALL || selectedCardItem?.type === ADT_TABLE_TYPE.TOTAL) {
				dataComparison = [];
				selectedFilterDataComparison = [];
			} else {
				dataComparison = selectedCardItem?.tableData || [];
				selectedFilterDataComparison = selectedCardItem?.selectedIds;
			}
			let patientFilterDataComparison = patientListComparison.list;
			if (transferType === ADMISSION_TYPES.ADMISSION || transferType === ADMISSION_TYPES.READMISSION) {
				patientFilterDataComparison = _.filter(patientListComparison.list, {
					type: transferType,
				});
			}
			let filterTotalByTransferTypeComparison = _(patientFilterDataComparison)
				.groupBy("facilityId")
				.sortBy((group) => patientFilterDataComparison.indexOf(group[0]))
				.map((product) => {
					return {
						id: product[0].facilityId || null,
						total: product.length || 0,
					};
				})
				.value();
			const filtersComparison = {
				facilityId: activeFacilitiesComparison[0],
				facilityIds: activeFacilitiesComparison,
				cardFilter: latestCardFilterComparison,
				selectedFilterData: selectedADTTableDataComparison,
				type: selectedCardItem,
				filter: filterComparison,
				transferType: transferTypeComparison,
				selectedCardItem,
				relation: selectedCardItem?.relation || null,
				mainPriorityData: cardFilterComparison?.mainPriorityData,
				filterTotalByTransferType: filterTotalByTransferTypeComparison,
				filterSelected:
					cardFilterComparison?.mainPriorityData.length > 0 &&
					cardFilterComparison?.mainPriorityData[0]["type"] !== cardFilterComparison?.type,
				adtFacilityPercentage: adtFacilityPercentageComparison,
				lockedByFacility: lockedByFacilityComparison,
				lockedTotal: lockedTotalComparison,
				isCensusTotalLocked: false,
			};
			dispatch(
				openADTDetailsDialogComparison({
					isOpen: true,
					selectedCardItem,
					type,
					data: dataComparison,
					filters: filtersComparison,
					admissionTotal: filterListDataComparison?.admissionTotal || 0,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			cardFilter,
			patientList.list,
			transferType,
			activeFacilities,
			filter,
			adtFacilityPercentage,
			lockedByFacility,
			lockedTotal,
			dispatch,
			filterListData.admissionTotal,
			cardFilterComparison,
			patientListComparison.list,
			activeFacilitiesComparison,
			selectedADTTableDataComparison,
			filterComparison,
			transferTypeComparison,
			adtFacilityPercentageComparison,
			lockedByFacilityComparison,
			lockedTotalComparison,
			filterListDataComparison?.admissionTotal,
		]
	);

	const handleChartDialog = useCallback(
		async (title, type, relation = null, isTotalCard = false) => {
			let latestCardFilter = await updateAdmissionChartFilter(cardFilter, type);
			latestCardFilter = _.omit(latestCardFilter, ["adtData", "cardFilter.mainPriorityData"]);
			let filterSelected =
				(cardFilter.mainPriorityData.length > 0 && cardFilter.mainPriorityData[0]["type"] !== cardFilter.type) ||
				transferType != null;
			if (
				type === ADMISSION_CARDS_TYPE.ADMISSION ||
				type === ADMISSION_CARDS_TYPE.READMISSION ||
				type === ADMISSION_CARDS_TYPE.TOTAL
			) {
				filterSelected = false;
			}
			const filters = {
				facilityId: localStorage.getItem("facilityId"),
				facilityIds: activeFacilities,
				cardFilter: latestCardFilter,
				selectedFilterData: cardFilter[type] || [],
				type,
				filter,
				relation,
				transferType,
				filterSelected,
				lockedByFacility,
				lockedTotal,
				isCensusTotalLocked,
				isTotalCard,
				selectedADTTableData,
				filterListData
			};
			let data = [];
			if (
				type === ADMISSION_CARDS_TYPE.ADMISSION ||
				type === ADMISSION_CARDS_TYPE.READMISSION ||
				type === ADMISSION_CARDS_TYPE.TOTAL
			) {
				data = [];
			} else {
				data = filterListData[type];
			}
			dispatch(
				openDetailsDialog({
					isOpen: true,
					title,
					type,
					data,
					filters,
					dbData,
				})
			);

			// COMPARISON ANALOG ------------>
			let latestCardFilterComparison = await updateAdmissionChartFilter(cardFilterComparison, type);
			latestCardFilterComparison = _.omit(latestCardFilterComparison, ["adtData", "cardFilter.mainPriorityData"]);
			let filterSelectedComparison =
				(cardFilterComparison.mainPriorityData.length > 0 &&
					cardFilterComparison.mainPriorityData[0]["type"] !== cardFilterComparison.type) ||
				transferType != null;
			if (
				type === ADMISSION_CARDS_TYPE.ADMISSION ||
				type === ADMISSION_CARDS_TYPE.READMISSION ||
				type === ADMISSION_CARDS_TYPE.TOTAL
			) {
				filterSelectedComparison = false;
			}
			const filtersComparison = {
				facilityId: activeFacilitiesComparison[0],
				facilityIds: activeFacilitiesComparison,
				cardFilter: latestCardFilterComparison,
				selectedFilterData: cardFilterComparison[type] || [],
				type,
				filter: filterComparison,
				relation,
				transferType: transferTypeComparison,
				filterSelected: filterSelectedComparison,
				lockedByFacility: lockedByFacilityComparison,
				lockedTotal: lockedTotalComparison,
				isCensusTotalLocked: isCensusTotalLockedComparison,
			};
			let dataComparison = [];
			if (
				type === ADMISSION_CARDS_TYPE.ADMISSION ||
				type === ADMISSION_CARDS_TYPE.READMISSION ||
				type === ADMISSION_CARDS_TYPE.TOTAL
			) {
				dataComparison = [];
			} else {
				dataComparison = filterListDataComparison[type];
			}
			dispatch(
				openDetailsDialogComparison({
					isOpen: true,
					title,
					type,
					data: dataComparison,
					filters: filtersComparison,
				})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, filterListData, cardFilter, filter, filterListDataComparison, cardFilterComparison, filterComparison]
	);

	const getPriorityNumber = useCallback(
		(type) => {
			const idx = cardFilter.mainPriorityData.findIndex((p) => p.type === type);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const getADTPriorityNumber = useCallback(
		(item) => {
			const idx = cardFilter.mainPriorityData.findIndex((p) => p.childId === item.childId && p.cardId === item.cardId);
			return idx != null && idx !== -1 ? idx + 1 : null;
		},
		[cardFilter]
	);

	const handleOnClickReport = async (
		type,
		title,
		isTotalCard = false,
		isAdtType = false,
		automaticallyReport = null
	) => {
		if (automaticallyReport) {
			await saveAutomaticReport({
				type,
				title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.ADMISSION,
				filterListData,
				isTotalCard,
				selectedADTTableData,
				isAdt: true,
				admissionReportType: isAdtType ? ADMISSION_FILTER_TYPE.ADT : ADMISSION_FILTER_TYPE.ADMISSION,
				automaticallyReport: automaticallyReport,
				transferType,
				lockedTotalBy,
				isCensusTotalLocked,
				lockeByADT,
				lastADTDate,
				percentageBy,
			});
		} else {
			await downloadPdf({
				type,
				title,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.ADMISSION,
				filterListData,
				isTotalCard,
				selectedADTTableData,
				isAdt: true,
				admissionReportType: isAdtType ? ADMISSION_FILTER_TYPE.ADT : ADMISSION_FILTER_TYPE.ADMISSION,
			});
		}
	};

	const handleOnClickReportGraph = async (graphData, isAdtType = false) => {
		if (graphData && graphData.automaticallyReport) {
			const graphDataUpdated = _.omit(graphData, ["chartData", "filterData", "tableElementRef", "buildingElementRef"]);
			await saveAutomaticReport({
				type: isAdtType ? graphData.filters.selectedCardItem : graphData?.filters?.type,
				title: graphData?.automaticallyReport?.title,
				cardFilter,
				filter: {
					...filter,
					startDate: toSaveDate(filter.startDate),
					endDate: toSaveEndDate(filter.endDate),
				},
				dbData,
				page: PAGE_TYPE.ADMISSION,
				filterListData,
				automaticallyReport: graphData?.automaticallyReport,
				transferType,
				lockedByFacility,
				lockedTotal,
				lockedTotalBy,
				isCensusTotalLocked,
				lastADTDate,
				isGraphReport: true,
				admissionReportType: isAdtType ? ADMISSION_FILTER_TYPE.ADT : ADMISSION_FILTER_TYPE.ADMISSION,
				graphData: { ...graphDataUpdated, detailsDialog },
				percentageBy,
			});
		} else {
			await downloadPdf({
				type: graphData?.filters?.type,
				cardFilter,
				filter,
				dbData,
				page: PAGE_TYPE.ADMISSION,
				filterListData,
				isTotalCard: false,
				selectedADTTableData,
				graphData,
				isGraphReport: true,
				isAdt: true,
				admissionReportType: isAdtType ? ADMISSION_FILTER_TYPE.ADT : ADMISSION_FILTER_TYPE.ADMISSION,
			});
		}
	};

	const handleAddCustomCard = useCallback(async (key) => {
		let cards = [...admissionCards];
		if (key === "selectAll") {
			cards = _.map(ADMISSION_CUSTOM_CARD, "value");
		} else if (key === "clearAll") {
			cards = []
		} else {
			if (!cards.includes(key)) {
				cards.push(key);
			} else {
				cards.splice(cards.indexOf(key), 1);
			}
		}
		dispatch(setAdmissionCards(cards));
		await saveCustomCardFilter({ page: PAGE_TYPE.ADMISSION, cards })
	}, [admissionCards, dispatch, saveCustomCardFilter]);

	const renderADTDataTables = useCallback(
		(item, i) => {
			const itemComparison = selectedADTTableDataComparison.find(
				(x) => x.childId === item?.childId && x.cardId === item?.cardId
			);
			const cardsThatDontCompareToDiffFacilities = [
				"Floor",
				"Doctor",
				"Per Nurse",
				"Hospitals resident transferred",
				"Per Facility",
			];
			const canBeSearchedCardSubTitles = [
				"Transfers Per Insurance",
				"Floor",
				"Doctor",
				"Per Nurse",
				"Diagnosis",
				"Hospitals resident transferred",
				"Safe discharges per Assisted Living",
				"Per Facility",
			];
			const dontCompareDiffFacilities = cardsThatDontCompareToDiffFacilities.some((x) => x === item?.subTitle);
			const canBeSearched = canBeSearchedCardSubTitles.some((x) => x === item.subTitle);
			const specificData = selectedADTTableData[i];

			return item.id === "all" && disableTotalHospitalizationsCard ? null : (
				<Grid item xs={4} key={item.id}>
					<ADTCard
						item={item}
						title={item.title}
						secondaryTitle={item.subTitle}
						helperText={item.helperText}
						minimizable={true}
						loading={loading || loadingComparison}
						cardFilter={cardFilter}
						filters={selectedADTTableData}
						reloadAdmissionFilter={reloadAdmissionFilter}
						defaultADTData={defaultADTData}
						handleOnClickDetails={(selectedCardItem) => handleADTTabChartDialog(selectedCardItem)}
						priorityNumber={getADTPriorityNumber(item)}
						handleOnClickReport={(title, otherData) => handleOnClickReport(item, title, false, true, otherData)}
						secondaryTitleError={
							comparingAgainstDifferentFacility &&
							rangesSet &&
							dontCompareDiffFacilities &&
							"Comparison function disabled when comparing to other facilities"
						}
						secondaryTitleClassName="error"
						specificData={specificData}
						type={"adtAdmitPatientIds"}
						handleToggleAll={handleToggleAllADTTab}
						selectedItems={cardFilter.adtAdmitPatientIds}
						canBeSearched={canBeSearched}
					>
						<TransferADTCardListTable
							item={item}
							itemComparison={itemComparison}
							dbSelectedFilters={dbSelectedFilters}
							filter={filter}
							filterComparison={filterComparison}
							dbSelectedFiltersComparison={dbSelectedFiltersComparison}
							handleToggleADTTab={handleToggleADTTab}
							handleToggleAll={handleToggleAllADTTab}
							filters={cardFilter.adtAdmitPatientIds}
							filtersComparison={cardFilterComparison?.adtAdmitPatientIds}
							type={"adtAdmitPatientIds"}
							page={PAGE_TYPE.ADMISSION}
							dbData={selectedADTTableData}
							dontCompareDiffFacilities={comparingAgainstDifferentFacility && dontCompareDiffFacilities}
							totalAdmissions={
								lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData?.bedCapacity
							}
							totalAdmissionsComparison={
								lockFilterTotalStateComparison?.isLocked
									? lockFilterTotalStateComparison?.lockedFilterTotal
									: dbDataComparison?.bedCapacity
							}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							priorityNumber={getADTPriorityNumber(item)}
							transferType={transferType}
							lockedTotalBy={filterListData.lockedTotalBy}
							specificData={specificData}
						/>
					</ADTCard>
				</Grid>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			selectedADTTableDataComparison,
			comparingAgainstDifferentFacility,
			rangesSet,
			loading,
			cardFilter,
			selectedADTTableData,
			reloadAdmissionFilter,
			defaultADTData,
			getADTPriorityNumber,
			dbSelectedFilters,
			dbSelectedFiltersComparison,
			cardFilterComparison?.adtAdmitPatientIds,
			lockFilterTotalState?.isLocked,
			lockFilterTotalState?.lockedFilterTotal,
			dbData.total,
			lockFilterTotalStateComparison?.isLocked,
			lockFilterTotalStateComparison?.lockedFilterTotal,
			dbDataComparison?.total,
			handleADTTabChartDialog,
			projectionDays,
			percentageAgainst,
		]
	);

	const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0 });
	const scrollDemoRef = useRef(null);
	const handleScroll = () => {
		if (scrollDemoRef.current) {
			const { scrollTop } = scrollDemoRef.current;
			setScrollPosition({ scrollTop });
		}
	};

	return (
		<Page title="Dashboard: Admissions">
			{detailsDialog.isOpen && (
				<AdmissionCardDetailsDialog
					handleGenerateExcelReport={downloadExcel}
					handleOnClickReport={handleOnClickReportGraph}
				/>
			)}
			{detailsADTDialog.isOpen && (
				<AdmissionADTDetailsDialog
					handleGenerateExcelReport={downloadExcel}
					handleOnClickReport={(data) => handleOnClickReportGraph(data, true)}
				/>
			)}
			<div ref={scrollDemoRef} className={dashboardStyles.content} onScroll={handleScroll}>
				<div className={`df aic ${styles.header}`}>
					<div className={`df aic ${styles.aoWrapper}`}>
						<AdmissionHeader
							handleFilterUpdate={() => setIsDateUpdated(true)}
							loading={loading || loadingComparison}
							setLoading={setLoading}
							setLoadingComparison={setLoadingComparison}
							projectionDays={projectionDays}
							isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
							percentageAgainst={percentageAgainst}
							projectionSelectionBlock={<PickProjectionDaysPopup open={projectionPopupOpen} setOpen={setProjectionPopupOpen} loading={loading} />}
						/>
					</div>
				</div>

				<NoteContainer page={PAGE_TYPE.OVERALL}>
					<Grid container justifyContent={"center"} sx={{ mt: "30px" }}>
						<Grid item xs={12} position={"relative"}>
							{rangesSet && <ColorInfoBox  style={{ right: "1%" }} loading={loading || loadingComparison} />}
							<AddCardDrawer
								listData={ADMISSION_CUSTOM_CARD}
								selectedCards={admissionCards}
								handleAddCustomCard={handleAddCustomCard}
								scrollTop={scrollPosition?.scrollTop}
							/>
							<TotalCountCircle
								page={PAGE_TYPE.ADMISSION}
								loading={loading || loadingComparison}
								totalTitle="Total Admissions"
								projectTitle={percentageBy}
								selected={transferType === ADMISSION_TYPES.ALL}
								handleClickTotal={() => handleClickTotal(dbData.total, dbDataComparison?.total)}
								total={dbData.total}
								totalComparison={dbDataComparison?.total}
								admissionCompareAgainst={
									lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
								}
								admissionCompareAgainstComparison={
									lockFilterTotalStateComparison?.isLocked
										? lockFilterTotalStateComparison?.lockedFilterTotal
										: dbDataComparison?.bedCapacity
								}
								dbData={dbData}
								dbDataComparison={dbDataComparison}
								filter={filter}
								filterComparison={filterComparison}
								totalPercentage={itemPercentage(dbData.total, percentageAgainst, "number")}
								totalComparisonPercentage={dbDataComparison?.total}
								totalProjectionPercentage={itemPercentage(
									projectionPerMonth(dbData.total, filter, projectionDays),
									percentageAgainst,
									"number"
								)}
								totalProjectionComparisonPercentage={itemPercentage(
									projectionPerMonth(dbDataComparison?.total, filterComparison, projectionDays),
									dbDataComparison?.total,
									"number"
								)}
								projectTotal={projectionPerMonth(dbData.total, filter, projectionDays)}
								projectTotalComparison={projectionPerMonth(dbDataComparison?.total, filterComparison, projectionDays)}
								tooltipContent={tooltipsInfo.admissionsPage.totalAdmissions}
								handleOnClickAction={(title) => handleChartDialog(title, ADMISSION_CARDS_TYPE.TOTAL, null, true)}
								handleOnClickReport={(title) => handleOnClickReport(ADMISSION_CARDS_TYPE.TOTAL, title, true)}
								comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
								projectionDays={projectionDays}
							/>
						</Grid>
					</Grid>
					<Box sx={{ p: 2 }}>
						{/* Transfer Type Selection */}
						<HospitalRowContainer>
							<Grid item md={6} xs={12}>
								<SelectableCard
									loading={loading || loadingComparison}
									title="New Admissions"
									disabled={transferType === ADMISSION_TYPES.READMISSION}
									selected={transferType === ADMISSION_TYPES.ADMISSION}
									handleOnclickCard={() =>
										handleOnclickCard(
											ADMISSION_TYPES.ADMISSION,
											dbData.totalAdmissions,
											dbData.total,
											dbDataComparison?.totalAdmissions,
											dbDataComparison?.total
										)
									}
									handleOnClickAction={(title) => handleChartDialog(title, ADMISSION_CARDS_TYPE.ADMISSION, null, true)}
									tooltipContent={tooltipsInfo.admissionsPage.newAdmissions}
									handleOnClickReport={(title, otherData) =>
										handleOnClickReport(ADMISSION_CARDS_TYPE.TOTAL_ADMISSIONS, title, true, false, otherData)
									}
								>
									<TotalTransferCard
										page={PAGE_TYPE.ADMISSION}
										title={"Total Admissions"}
										secondaryTitle={percentageBy}
										dbData={dbData}
										loading={loading || loadingComparison}
										dbDataComparison={dbDataComparison}
										total={dbData.totalAdmissions}
										totalComparison={dbDataComparison?.totalAdmissions}
										totalPercentage={itemPercentage(dbData.totalAdmissions, percentageAgainst, "number")}
										totalProjection={projectionPerMonth(dbData.totalAdmissions, filter, projectionDays)}
										totalProjectionComparison={projectionPerMonth(
											dbDataComparison?.totalAdmissions,
											filterComparison,
											projectionDays
										)}
										filter={filter}
										filterComparison={filterComparison}
										admissionCompareAgainst={
											lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
										}
										admissionCompareAgainstComparison={
											lockFilterTotalStateComparison?.isLocked
												? lockFilterTotalStateComparison?.lockedFilterTotal
												: dbDataComparison?.bedCapacity
										}
										totalComparisonPercentage={dbDataComparison?.totalAdmissions}
										totalProjectionPercentage={itemPercentage(
											projectionPerMonth(dbData.totalAdmissions, filter, projectionDays),
											percentageAgainst,
											"number"
										)}
										reverseColorsAdmissionPage={reversedColorAdmission}
										secondaryText={""}
										comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
										projectionDays={projectionDays}
									/>
								</SelectableCard>
							</Grid>
							<Grid item md={6} xs={12}>
								<SelectableCard
									flex="1"
									title="Re-Admissions"
									disabled={transferType === ADMISSION_TYPES.ADMISSION}
									selected={transferType === ADMISSION_TYPES.READMISSION}
									handleOnclickCard={() =>
										handleOnclickCard(
											ADMISSION_TYPES.READMISSION,
											dbData.totalReAdmissions,
											dbData.total,
											dbDataComparison?.totalAdmissions,
											dbDataComparison?.total
										)
									}
									handleOnClickAction={(title) => handleChartDialog(title, ADMISSION_CARDS_TYPE.READMISSION, null, true)}
									tooltipContent={tooltipsInfo.admissionsPage.reAdmissions}
									loading={loading || loadingComparison}
									handleOnClickReport={(title) => handleOnClickReport(ADMISSION_CARDS_TYPE.READMISSION, title, true)}
								>
									<TotalTransferCard
										page={PAGE_TYPE.ADMISSION}
										title={"Total Re-Admissions"}
										dbData={dbData}
										dbDataComparison={dbDataComparison}
										total={dbData.totalReAdmissions}
										loading={loading || loadingComparison}
										admissionCompareAgainst={
											lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
										}
										admissionCompareAgainstComparison={
											lockFilterTotalStateComparison?.isLocked
												? lockFilterTotalStateComparison?.lockedFilterTotal
												: dbDataComparison?.bedCapacity
										}
										totalComparison={dbDataComparison?.totalReAdmissions}
										totalPercentage={itemPercentage(dbData.totalReAdmissions, percentageAgainst, "number")}
										totalProjection={projectionPerMonth(dbData.totalReAdmissions, filter, projectionDays)}
										totalProjectionComparison={projectionPerMonth(
											dbDataComparison?.totalReAdmissions,
											filterComparison,
											projectionDays
										)}
										filter={filter}
										filterComparison={filterComparison}
										totalComparisonPercentage={dbDataComparison?.totalReAdmissions}
										totalProjectionPercentage={itemPercentage(
											projectionPerMonth(dbData.totalReAdmissions, filter, projectionDays),
											percentageAgainst,
											"number"
										)}
										reverseColorsAdmissionPage={reversedColorAdmission}
										secondaryTitle={percentageBy}
										secondaryText={""}
										comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
										projectionDays={projectionDays}
									/>
								</SelectableCard>
							</Grid>
						</HospitalRowContainer>
						{/* End of Transfer Type Selection */}

						<HospitalRowContainer>
							<CardAnimation id={`${ADMISSION_CARDS_TYPE.DOCTOR_DATA}_scroll`} checked={_.includes(admissionCards, ADMISSION_CARDS_TYPE.DOCTOR_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										title="Per Doctor"
										// minimizable={true}
										taggedList={
											<TaggedList
												type={ADMISSION_CARDS_TYPE.DOCTOR_DATA}
												data={filterListData.doctorData}
												selectedItem={cardFilter.doctorData}
											/>
										}
										tooltipContent={tooltipsInfo.admissionsPage.perDoctor}
										handleOnClickAction={(title) =>
											handleChartDialog(title, ADMISSION_CARDS_TYPE.DOCTOR_DATA, PATIENT_RELATION.DOCTOR)
										}
										priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.DOCTOR_DATA)}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(ADMISSION_CARDS_TYPE.DOCTOR_DATA, title, false, false, otherData)
										}
										secondaryTitleError={
											comparingAgainstDifferentFacility &&
											rangesSet &&
											"Comparison function disabled when comparing to other facilities"
										}
										secondaryTitleClassName="error"
										selectedItems={cardFilter.doctorData}
										data={filterListData.doctorData}
										type={ADMISSION_CARDS_TYPE.DOCTOR_DATA}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<DoctorCard
											cardTitle="Per Doctor"
											data={filterListData.doctorData}
											comparisonData={filterListDataComparison?.doctorData}
											totalAdmissions={dbData.total}
											totalAdmissionsComparison={dbDataComparison?.total}
											minimizable={true}
											admissionCompareAgainst={
												lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
											}
											admissionCompareAgainstComparison={
												lockFilterTotalStateComparison?.isLocked
													? lockFilterTotalStateComparison?.lockedFilterTotal
													: dbDataComparison?.bedCapacity
											}
											handleToggle={handleToggle}
											handleToggleAll={handleToggleAll}
											selectedItem={cardFilter.doctorData}
											type={ADMISSION_CARDS_TYPE.DOCTOR_DATA}
											page={PAGE_TYPE.ADMISSION}
											filter={filter}
											loading={loading || loadingComparison}
											reverseColorsAdmissionPage={reversedColorAdmission}
											filterComparison={filterComparison}
											comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
											projectionDays={projectionDays}
											averageCensus={percentageAgainst}
											filterTotal={filterTotal}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.DOCTOR_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${ADMISSION_CARDS_TYPE.DAYS_DATA}_scroll`} checked={_.includes(admissionCards, ADMISSION_CARDS_TYPE.DAYS_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										title="Per Day"
										// minimizable={true}
										handleOnClickAction={(title) => handleChartDialog(title, ADMISSION_CARDS_TYPE.DAYS_DATA)}
										taggedList={
											<TaggedList
												type={ADMISSION_CARDS_TYPE.DAYS_DATA}
												data={filterListData.daysData}
												selectedItem={cardFilter.daysData}
											/>
										}
										tooltipContent={tooltipsInfo.admissionsPage.perDayOfWeek}
										priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.DAYS_DATA)}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(ADMISSION_CARDS_TYPE.DAYS_DATA, title, false, false, otherData)
										}
									>
										<DayCardList
											cardTitle="Per Day"
											dbData={dbData}
											dbDataComparison={dbDataComparison}
											data={filterListData.daysData}
											dataComparison={filterListDataComparison?.daysData}
											totalAdmissions={dbData.total}
											totalAdmissionsComparison={dbDataComparison?.total}
											handleToggle={handleToggle}
											filter={filter}
											filterComparison={filterComparison}
											admissionCompareAgainst={
												lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
											}
											admissionCompareAgainstComparison={
												lockFilterTotalStateComparison?.isLocked
													? lockFilterTotalStateComparison?.lockedFilterTotal
													: dbDataComparison?.bedCapacity
											}
											selectedItem={cardFilter.daysData}
											type={ADMISSION_CARDS_TYPE.DAYS_DATA}
											page={PAGE_TYPE.ADMISSION}
											loading={loading || loadingComparison}
											reverseColorsAdmissionPage={reversedColorAdmission}
											comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
											projectionDays={projectionDays}
											filterTotal={filterTotal}
											priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.DAYS_DATA)}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${ADMISSION_CARDS_TYPE.DX_DATA}_scroll`} checked={_.includes(admissionCards, ADMISSION_CARDS_TYPE.DX_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										title="Per Diagnosis"
										// minimizable={true}
										handleOnClickAction={(title) =>
											handleChartDialog(title, ADMISSION_CARDS_TYPE.DX_DATA, PATIENT_RELATION.DX)
										}
										taggedList={
											<TaggedList
												type={ADMISSION_CARDS_TYPE.DX_DATA}
												data={filterListData.dxData}
												selectedItem={cardFilter.dxData}
											/>
										}
										tooltipContent={tooltipsInfo.admissionsPage.perDiagnosis}
										priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.DX_DATA)}
										handleOnClickReport={(title) => handleOnClickReport(ADMISSION_CARDS_TYPE.DX_DATA, title)}
										selectedItems={cardFilter.dxData}
										data={filterListData.dxData}
										type={ADMISSION_CARDS_TYPE.DX_DATA}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<DxCardList
											cardTitle="Per Diagnosis"
											data={filterListData.dxData}
											dataComparison={filterListDataComparison?.dxData}
											filter={filter}
											filterComparison={filterComparison}
											admissionCompareAgainst={
												lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
											}
											admissionCompareAgainstComparison={
												lockFilterTotalStateComparison?.isLocked
													? lockFilterTotalStateComparison?.lockedFilterTotal
													: dbDataComparison?.bedCapacity
											}
											handleToggle={handleToggle}
											selectedItem={cardFilter.dxData}
											type={ADMISSION_CARDS_TYPE.DX_DATA}
											page={PAGE_TYPE.ADMISSION}
											loading={loading || loadingComparison}
											reverseColorsAdmissionPage={reversedColorAdmission}
											comparingAgainstDifferentFacility={false}
											projectionDays={projectionDays}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.DX_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${ADMISSION_CARDS_TYPE.INSURANCE_DATA}_scroll`} checked={_.includes(admissionCards, ADMISSION_CARDS_TYPE.INSURANCE_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										title="Per Insurance"
										itemAlign={"ais"}
										handleOnClickAction={(title) =>
											handleChartDialog(title, ADMISSION_CARDS_TYPE.INSURANCE_DATA, PATIENT_RELATION.PAYER_INSURANCE)
										}
										tooltipContent={tooltipsInfo.admissionsPage.perInsurance}
										priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.INSURANCE_DATA)}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(ADMISSION_CARDS_TYPE.INSURANCE_DATA, title, false, false, otherData)
										}
										selectedItems={cardFilter.insuranceData}
										data={filterListData.insuranceData}
										type={ADMISSION_CARDS_TYPE.INSURANCE_DATA}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<InsuranceCard
											dbData={dbData}
											dbDataComparison={dbDataComparison}
											cardTitle="Per Insurance"
											data={filterListData.insuranceData}
											dataComparison={filterListDataComparison?.insuranceData}
											totalAdmissions={dbData.total}
											totalAdmissionsComparison={dbDataComparison?.total}
											handleToggle={handleToggle}
											selectedItem={cardFilter.insuranceData}
											type={ADMISSION_CARDS_TYPE.INSURANCE_DATA}
											page={PAGE_TYPE.ADMISSION}
											filter={filter}
											admissionCompareAgainst={
												lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
											}
											admissionCompareAgainstComparison={
												lockFilterTotalStateComparison?.isLocked
													? lockFilterTotalStateComparison?.lockedFilterTotal
													: dbDataComparison?.bedCapacity
											}
											filterComparison={filterComparison}
											reverseColorsAdmissionPage={reversedColorAdmission}
											comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
											loading={loading || loadingComparison}
											projectionDays={projectionDays}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.INSURANCE_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${ADMISSION_CARDS_TYPE.FLOORS_DATA}_scroll`} checked={_.includes(admissionCards, ADMISSION_CARDS_TYPE.FLOORS_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										title="Per Floor"
										// minimizable={true}
										handleOnClickAction={(title) =>
											handleChartDialog(title, ADMISSION_CARDS_TYPE.FLOORS_DATA, PATIENT_RELATION.UNIT)
										}
										taggedList={
											<TaggedList
												type={ADMISSION_CARDS_TYPE.FLOORS_DATA}
												data={filterListData.floorsData}
												selectedItem={cardFilter.floorsData}
											/>
										}
										tooltipContent={tooltipsInfo.admissionsPage.perFloor}
										priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.FLOORS_DATA)}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(ADMISSION_CARDS_TYPE.FLOORS_DATA, title, false, false, otherData)
										}
										secondaryTitleError={
											comparingAgainstDifferentFacility &&
											rangesSet &&
											"Comparison function disabled when comparing to other facilities"
										}
										secondaryTitleClassName="error"
										selectedItems={cardFilter.floorsData}
										data={filterListData.floorsData}
										type={ADMISSION_CARDS_TYPE.FLOORS_DATA}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<FloorCardList
											cardTitle="Per Floor"
											data={filterListData.floorsData}
											dataComparison={filterListDataComparison?.floorsData}
											totalAdmissions={dbData.total}
											totalAdmissionsComparison={dbDataComparison?.total}
											handleToggle={handleToggle}
											filter={filter}
											filterComparison={filterComparison}
											selectedItem={cardFilter.floorsData}
											type={ADMISSION_CARDS_TYPE.FLOORS_DATA}
											page={PAGE_TYPE.ADMISSION}
											admissionCompareAgainst={
												lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
											}
											admissionCompareAgainstComparison={
												lockFilterTotalStateComparison?.isLocked
													? lockFilterTotalStateComparison?.lockedFilterTotal
													: dbDataComparison?.bedCapacity
											}
											reverseColorsAdmissionPage={reversedColorAdmission}
											comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
											loading={loading || loadingComparison}
											projectionDays={projectionDays}
											isComparingAgainstAvgCensus={filterListData.isComparingAgainstAvgCensus}
											priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.FLOORS_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>

							<CardAnimation id={`${ADMISSION_CARDS_TYPE.HOSPITAL_DATA}_scroll`} checked={_.includes(admissionCards, ADMISSION_CARDS_TYPE.HOSPITAL_DATA)}>
								<Grid item md={4} xs={12}>
									<Card
										loading={loading || loadingComparison}
										title="Per Hospital"
										// minimizable={true}
										handleOnClickAction={(title) =>
											handleChartDialog(title, ADMISSION_CARDS_TYPE.HOSPITAL_DATA, PATIENT_RELATION.HOSPITAL)
										}
										taggedList={
											<TaggedList
												type={ADMISSION_CARDS_TYPE.HOSPITAL_DATA}
												data={filterListData.hospitalData}
												selectedItem={cardFilter.hospitalData}
											/>
										}
										tooltipContent={tooltipsInfo.admissionsPage.perHospital}
										priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.HOSPITAL_DATA)}
										handleOnClickReport={(title, otherData) =>
											handleOnClickReport(ADMISSION_CARDS_TYPE.HOSPITAL_DATA, title, false, false, otherData)
										}
										secondaryTitleError={
											comparingAgainstDifferentFacility &&
											rangesSet &&
											"Comparison function disabled when comparing to other facilities"
										}
										secondaryTitleClassName="error"
										selectedItems={cardFilter.hospitalData}
										data={filterListData.hospitalData}
										type={ADMISSION_CARDS_TYPE.HOSPITAL_DATA}
										handleToggleAll={handleToggleAll}
										canBeSearched={true}
									>
										<HospitalList
											dbData={dbData}
											dbDataComparison={dbDataComparison}
											cardTitle="Per Hospital"
											total={dbData.total}
											totalComparison={dbDataComparison?.total}
											data={filterListData.hospitalData}
											dataComparison={filterListDataComparison?.hospitalData}
											filter={filter}
											filterComparison={filterComparison}
											handleToggle={handleToggle}
											selectedItem={cardFilter.hospitalData}
											type={ADMISSION_CARDS_TYPE.HOSPITAL_DATA}
											page={PAGE_TYPE.ADMISSION}
											admissionCompareAgainst={
												lockFilterTotalState?.isLocked ? lockFilterTotalState?.lockedFilterTotal : dbData.bedCapacity
											}
											admissionCompareAgainstComparison={
												lockFilterTotalStateComparison?.isLocked
													? lockFilterTotalStateComparison?.lockedFilterTotal
													: dbDataComparison?.bedCapacity
											}
											loading={loading || loadingComparison}
											reverseColorsAdmissionPage={reversedColorAdmission}
											comparingAgainstDifferentFacility={comparingAgainstDifferentFacility}
											projectionDays={projectionDays}
											filterTotal={filterTotal}
											averageCensus={percentageAgainst}
											priorityData={cardFilter.mainPriorityData}
											priorityNumber={getPriorityNumber(ADMISSION_CARDS_TYPE.HOSPITAL_DATA)}
											transferType={transferType}
											lockedTotalBy={lockedTotalBy}
										/>
									</Card>
								</Grid>
							</CardAnimation>
						</HospitalRowContainer>

						<HospitalRowContainer sx={{ mb: "80px" }}>
							<CardAnimation id={`${ADMISSION_CARDS_TYPE.ADT_TAB}_scroll`} checked={_.includes(admissionCards, ADMISSION_CARDS_TYPE.ADT_TAB)}>
								<Grid item xs={12}>
									<ViewTransferStatusOfAdmission
										isDetailsOpen={true}
										handleOnClickRun={() => {
											handleOnClickRun(patientList);
											if (filterComparison && rangesSet) {
												setTimeout(() => {
													handleOnClickRun(patientListComparison, true);
												}, 2000);
											}
										}}
										loading={loading || loadingComparison}
									/>
								</Grid>
							</CardAnimation>
							{!isAdtLoading &&
								!loading &&
								selectedADTTableData &&
								selectedADTTableData.length > 0 &&
								selectedADTTableData.map(renderADTDataTables)}
						</HospitalRowContainer>
					</Box>
				</NoteContainer>
				<ShadowLineWithText data={admissionCards} />
			</div>
		</Page>
	);
};

export default AdmissionPage;