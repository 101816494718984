import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({
    handleClose,
    content = '',
    title = '',
    handleSubmit,
    isConfirm = true,
    onlyOkay = false,
    maxWidth = 'sm'
}) {

    return (
        <Dialog
            sx={{ zIndex: 9999 }}
            maxWidth={maxWidth}
            open={true}
            hideBackdrop={true}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {title && (
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
            )}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {onlyOkay ? (
                    <Button onClick={handleClose} variant='contained' color='primary'>Okay</Button>
                ) : (
                    <>
                        <Button onClick={handleClose}>Cancel</Button>
                        {isConfirm && (
                            <Button onClick={handleSubmit} autoFocus>
                                Confirm
                            </Button>
                        )}
                    </>
                )
                }

            </DialogActions >
        </Dialog >
    );
}
