/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeDetailsDialog } from "../../../store/reducers/overall.slice";
import { cardPatientAndChartData } from "../../../services/overall.service";
import _ from "lodash";
import CardDetailChart from "../../shared/chart-dialog-common/chart-tab/CardDetailChart";
import {
	chartFilterPermission,
	chartGroupBy,
	chartsData,
	chartsOverallData,
	dayDifferent,
	getOverallFieldNames,
	isUpdateDefaultFilter,
} from "../../../utilis/charts-common";
import { CHART_FILTER_DAY_OF, DASHBOARD_FILTER_TYPE, FILTER_TYPES } from "../../../types/common.type";
import {
	CHART_POINT_TYPE,
	CHART_TAB_BUTTON,
} from "../../../types/chart-dialog.type";
import ChartDialogLeftSidebar from "../../shared/chart-dialog-common/ChartDialogLeftSidebar";
import ChartDetailsTab from "../../shared/chart-dialog-common/ChartDetailsTab";
import ChartTabLoader from "../../shared/chart-dialog-common/ChartTabLoader";
import ChartDialogContainer from "../../shared/chart-dialog-common/ChartDialogContainer";
import ChartTableList from "../../shared/chart-dialog-common/chart-table/ChartTableList";
import { OVERALL_CARDS_TYPE } from "../../../types/overall.type";
import { DEFAULT_CHART_DATA, DEFAULT_CHART_FILTER, OverallAdtOptions } from "../../../data/common.data";
import ChartBuildingList from "../../shared/chart-dialog-common/chart-building/ChartBuildingList";
import { getAccountQuestions } from "../../../services/api/user.api";
import DropDown from "../../ui/drop-down/DropDown";
import { getChartFacilityPercentageBy, patientDataOrderBy } from "../../../utilis/common";
import { PAGE_TYPE } from "../../../types/pages.type";
import ChartDetailsSearchBar, { tableDefaultQuestions } from "../../shared/chart-dialog-common/ChartDetailsSearchBar";
import NoteContainer from "../../shared/NoteContainer";

export default function OverallCardDetailsDialog({ handleOnClickReport, handleGenerateExcelReport }) {
	const tableElementRef = useRef();
	const buildingElementRef = useRef();
	const [selectedItem, setSelectedItem] = useState([]);
	const [loading, setLoading] = useState(false);
	const [buttonFilterType, setButtonFilterType] = useState(CHART_FILTER_DAY_OF.DAY);
	const [censusAverage, setCensusAverage] = useState([]);
	const [censusByPeriod, setCensusByPeriod] = useState([]);
	const [queryFilters, setQueryFilters] = useState(DEFAULT_CHART_FILTER);
	const [selectedTab, setSelectedTab] = useState(CHART_TAB_BUTTON.TABLE);
	const [defaultPatientData, setDefaultPatientData] = useState([]);
	const [filterData, setFilterData] = useState([]);
	const [chartData, setChartData] = useState(DEFAULT_CHART_DATA);
	const dispatch = useDispatch();
	const [selectedFilter, setSelectedFilter] = useState(FILTER_TYPES.DAILY);
	const {
		detailsDialog: { isOpen, title, data, filters, dbData },
	} = useSelector((state) => state.overall);
	const [isShowPercentage, setIsShowPercentage] = useState(false);
	const [censusByFacility, setCensusByFacility] = useState([]);
	const { auth } = useSelector(({ auth }) => ({ auth }));
	const [questions, setQuestions] = useState([]);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const [defaultQuestions, setDefaultQuestions] = useState([]);
	const [tableFilterType, setTableFilterType] = useState("all");

	const [selectedFacility, setSelectedFacility] = useState(activeFacilities);
	const [orderBy, setOrderBy] = useState('dateOfADT'); // Default sort column
	const [order, setOrder] = useState('asc'); // Default sort direction
	// COMPARISON STATES ---------->
	const {
		detailsDialog: {
			isOpen: isOpenComparison,
			title: titleComparison,
			data: dataComparison,
			filters: filtersComparison,
		},
		detailsDialog: detailsDialogComparison,
	} = useSelector((state) => state.overallComparison);
	const [chartDataComparison, setChartDataComparison] = useState(DEFAULT_CHART_DATA);
	const [censusAverageComparison, setCensusAverageComparison] = useState([]);
	const [censusByPeriodComparison, setCensusByPeriodComparison] = useState([]);
	const [censusByFacilityComparison, setCensusByFacilityComparison] = useState([]);
	const [queryFiltersComparison, setQueryFiltersComparison] = useState(DEFAULT_CHART_FILTER);
	const [defaultPatientDataComparison, setDefaultPatientDataComparison] = useState([]);
	const [filterDataComparison, setFilterDataComparison] = useState([]);
	const [selectedItemComparison, setSelectedItemComparison] = useState([]);

	const getQuestions = async (activeFacilitiesData) => {
		const res = await getAccountQuestions({
			facilityid: activeFacilitiesData,
			accountId: auth.accountId,
			pageType: DASHBOARD_FILTER_TYPE.OVERALL
		})
		if (res && res.length > 0) {
			setDefaultQuestions(res);
		}
	};

	React.useEffect(() => {
		getQuestions(activeFacilities);
		// eslint-disable-next-line
	}, [activeFacilities]);

	React.useEffect(() => {
		if (tableFilterType === "all") {
			setQuestions([])
		} else if (tableFilterType === "admission" || tableFilterType === "readmission") {
			setQuestions(_.filter(defaultQuestions, { forType: "admission" }));
		} else {
			setQuestions(_.filter(defaultQuestions, { forTransferType: tableFilterType }));
		}
		// eslint-disable-next-line
	}, [tableFilterType, defaultQuestions]);



	// * added comparison
	const updateChartArrData = useCallback(
		async (dataArr, filterType = null, type, filter = null, forComparison) => {
			try {
				let filterBy = filterType;
				if (!filterType) {
					filterBy = buttonFilterType;
				}
				let chartDataArr = [];

				if (filters.type === OVERALL_CARDS_TYPE.TOTAL_INCOMING || filters.type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING) {
					if (type === "filter" && filter) {
						chartDataArr = await chartsData(dataArr, filter);
					} else {
						chartDataArr = await chartsData(dataArr, queryFilters);
					}
				} else if (filters.transferType === OVERALL_CARDS_TYPE.TOTAL || filters.type === OVERALL_CARDS_TYPE.TOTAL) {
					if (type === "filter" && filter) {
						chartDataArr = await chartsOverallData(dataArr, filter);
					} else {
						chartDataArr = await chartsOverallData(dataArr, queryFilters);
					}
				} else {
					if (type === "filter" && filter) {
						chartDataArr = await chartsData(dataArr, filter);
					} else {
						chartDataArr = await chartsData(dataArr, queryFilters);
					}
				}
				let filtersLatest = filter ? filter : forComparison ? queryFiltersComparison : queryFilters;
				const latestChartData = await chartGroupBy(
					chartDataArr,
					filterBy,
					forComparison ? censusAverage : censusAverageComparison,
					forComparison ? censusByPeriod : censusByPeriodComparison,
					filtersLatest
				);
				if (type === "filter") {
					forComparison
						? setChartDataComparison((prevState) => ({
							...prevState,
							filterData: latestChartData,
							filterPatients: dataArr,
						}))
						: setChartData((prevState) => ({
							...prevState,
							filterData: latestChartData,
							filterPatients: dataArr,
						}));
				} else {
					forComparison
						? setChartDataComparison((prevState) => ({
							...prevState,
							filterData: latestChartData,
						}))
						: setChartData((prevState) => ({
							...prevState,
							filterData: latestChartData,
						}));
				}
			} catch (e) {
				console.log(e);
			}
		},
		[buttonFilterType, censusAverage, queryFilters, censusByPeriod]
	);

	// * added comparison
	const filterOptions = async (
		newChecked,
		chartMainData = [],
		latestButtonFilterType = null,
		filterObj = null,
		filterType = "initial",
		originalData,
		forComparison
	) => {
		let chartMainDataArr = chartMainData;
		if (activeFacilities.length > 1) {
			const filterPatientsByFacility = _.filter(chartMainDataArr, ({ facilityId }) => _.every([_.includes(selectedFacility, facilityId)]));
			chartMainDataArr = filterPatientsByFacility
		}
		if (filters.type === OVERALL_CARDS_TYPE.NINETY_DAYS_DATA) {
			let ninetyDaysDataIds = [];
			let dataOriginal = filterObj?.filterData
				? filterObj.filterData
				: forComparison
					? filterDataComparison
					: filterData;

			if (filterType === "reload") {
				dataOriginal = originalData.ninetyDaysData;
			}
			let ninetyDaysDataFilter = _.filter(dataOriginal, ({ _id }) => _.every([_.includes(newChecked, _id)]));
			if (ninetyDaysDataFilter && ninetyDaysDataFilter.length > 0) {
				ninetyDaysDataFilter.map((item) => (ninetyDaysDataIds = [...ninetyDaysDataIds, ...item.ids]));
			}
			let newChartFilters = _.filter(chartMainDataArr, ({ _id }) => _.every([_.includes(ninetyDaysDataIds, _id)]));
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		} else {
			let newChartFilters = filters?.isTotalCard ? chartMainDataArr : _.filter(chartMainDataArr, ({ filterId }) => {
				return _.every([_.includes(newChecked, filterId)]);
			});
			await updateChartArrData(newChartFilters, latestButtonFilterType, "filter", filterObj, forComparison);
		}
	};

	useEffect(() => {
		filterOptions(selectedItem,
			chartData?.mainData,
			buttonFilterType,
			{
				...filters?.filter,
			},
			undefined,
			undefined,
			false
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFacility]);

	// * added comparison
	const getPatientChartsData = async () => {
		try {
			setLoading(true);
			if (
				filters.type === OVERALL_CARDS_TYPE.TOTAL_INCOMING ||
				filters.type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING ||
				filters.type === OVERALL_CARDS_TYPE.TOTAL
			) {
				setIsShowPercentage(true);
			}
			const [response, responseComparison] = await Promise.all([
				cardPatientAndChartData({ ...filters }, "main"),
				filtersComparison && cardPatientAndChartData({ ...filtersComparison }, "comparison"),
			]);

			let latestButtonFilterType = buttonFilterType;
			const isUpdateFilter = isUpdateDefaultFilter(filters.filter);
			if (isUpdateFilter) {
				setSelectedFilter(FILTER_TYPES.WEEKLY);
				latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
				setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
			}
			if (response && response.data) {
				setCensusAverage(response?.censusAverage || 0);
				setCensusByPeriod(response?.censusByPeriod || []);
				const censusByData = await getChartFacilityPercentageBy(response)
				setCensusByFacility(censusByData);
				const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
				setDefaultPatientData(responseData);
				let chartDataArr = [];

				if (filters.type === OVERALL_CARDS_TYPE.TOTAL_INCOMING || filters.type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING) {
					chartDataArr = await chartsData(responseData, filters.filter);
				} else if (filters.transferType === OVERALL_CARDS_TYPE.TOTAL || filters.type === OVERALL_CARDS_TYPE.TOTAL) {
					chartDataArr = await chartsOverallData(responseData, filters.filter);
				} else {
					chartDataArr = await chartsData(responseData, filters.filter);
				}
				const latestChartData = await chartGroupBy(
					chartDataArr,
					latestButtonFilterType,
					response?.censusAverage,
					response?.censusByPeriod,
					filters.filter
				);
				setChartData({
					mainData: responseData,
					filterPatients: responseData,
					filterData: latestChartData,
				});
			}
			await setFilterData(data);
			if (filters.selectedFilterData && filters.selectedFilterData.length > 0) {
				setSelectedItem([...filters.selectedFilterData]);
				const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
				filterOptions(filters.selectedFilterData, responseData, latestButtonFilterType, {
					...filters.filter,
					filterData: data,
				});
			} else {
				const selectedIds = _.map(data, "_id") || [];
				setSelectedItem(selectedIds);
			}

			if (responseComparison && responseComparison.data) {
				setCensusAverageComparison(responseComparison?.censusAverage || 0);
				setCensusByPeriodComparison(responseComparison?.censusByPeriod || []);
				if (responseComparison.censusByFacility) {
					setCensusByFacilityComparison(responseComparison?.censusByFacility || []);
				}
				const responseDataComparison = responseComparison.data;
				setDefaultPatientDataComparison(responseDataComparison);
				let chartDataArrComparison = [];

				if (
					filtersComparison?.type === OVERALL_CARDS_TYPE.TOTAL_INCOMING ||
					filtersComparison?.type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING
				) {
					chartDataArrComparison = await chartsData(responseDataComparison, filters.filter);
				} else if (
					filters.transferType === OVERALL_CARDS_TYPE.TOTAL ||
					filtersComparison?.type === OVERALL_CARDS_TYPE.TOTAL
				) {
					chartDataArrComparison = await chartsOverallData(responseDataComparison, filtersComparison?.filter);
				} else {
					chartDataArrComparison = await chartsData(responseDataComparison, filtersComparison?.filter);
				}
				const latestChartDataComparison = await chartGroupBy(
					chartDataArrComparison,
					latestButtonFilterType,
					responseComparison?.censusAverage,
					responseComparison?.censusByPeriod,
					filtersComparison?.filter
				);
				setChartDataComparison({
					mainData: responseDataComparison,
					filterPatients: responseDataComparison,
					filterData: latestChartDataComparison,
				});
			}
			setFilterDataComparison(dataComparison);
			if (filtersComparison?.selectedFilterData && filtersComparison?.selectedFilterData.length > 0) {
				setSelectedItemComparison([...filtersComparison?.selectedFilterData]);
				filterOptions(filtersComparison?.selectedFilterData, responseComparison.data, latestButtonFilterType, {
					...filtersComparison?.filter,
					filterData: data,
				});
			} else {
				const selectedIdsComparison = _.map(dataComparison, "_id") || [];
				setSelectedItemComparison(selectedIdsComparison);
			}
			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	};

	// * added comparison
	const reloadChartData = useCallback(
		async (startDate, endDate, startDateComparison, endDateComparison) => {
			try {
				setLoading(true);
				let filterObj = { ...filters, filter: { startDate, endDate } };
				let filterObjComparison = {
					...filtersComparison,
					filter: { startDate: startDateComparison, endDate: endDateComparison },
				};

				let latestButtonFilterType = buttonFilterType;
				const isUpdateFilter = isUpdateDefaultFilter(filterObj.filter);
				if (isUpdateFilter) {
					setSelectedFilter(FILTER_TYPES.WEEKLY);
					latestButtonFilterType = CHART_FILTER_DAY_OF.WEEK;
					setButtonFilterType(CHART_FILTER_DAY_OF.WEEK);
				}
				const [response, responseComparison] = await Promise.all([
					cardPatientAndChartData({ ...filterObj }),
					filterObj && cardPatientAndChartData({ ...filterObjComparison }),
				]);

				if (response && response.data && response.data.length > 0) {
					const responseData = response?.data?.map((ele) => { return { ...ele, facilityName: ele?.facility?.name } }) ?? []
					setCensusAverage(response.censusAverage);
					setCensusByPeriod(response?.censusByPeriod || []);
					const censusByData = await getChartFacilityPercentageBy(response)
					setCensusByFacility(censusByData);
					let chartDataArr = [];

					if (
						filters.type === OVERALL_CARDS_TYPE.TOTAL_INCOMING ||
						filters.type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING
					) {
						chartDataArr = await chartsData(responseData, filterObj.filter);
					} else if (filters.transferType === OVERALL_CARDS_TYPE.TOTAL || filters.type === OVERALL_CARDS_TYPE.TOTAL) {
						chartDataArr = await chartsOverallData(responseData, { startDate, endDate });
					} else {
						chartDataArr = await chartsData(responseData, filterObj.filter);
					}
					const latestChartData = await chartGroupBy(
						chartDataArr,
						latestButtonFilterType,
						response.censusAverage,
						response?.censusByPeriod,
						filterObj.filter
					);
					setChartData({
						mainData: responseData,
						filterPatients: responseData,
						filterData: latestChartData,
					});

					let originalChartData = [];
					if (response.ninetyDaysData && response.ninetyDaysData.length > 0 && filters.type === "ninetyDaysData") {
						originalChartData = response.ninetyDaysData;
					}
					if (filterData.length > 0 && originalChartData.length > 0) {
						const latestFilterData = filterData
							.map((eleFilter) => {
								const selectedEle = _.find(originalChartData, { _id: eleFilter._id });
								if (selectedEle) {
									return {
										...eleFilter,
										admissionIds: selectedEle.admissionIds,
										ids: selectedEle.ids,
									};
								} else {
									return null;
								}
							})
							.filter((item) => item);
						setFilterData(latestFilterData);
					}
					if (selectedItem.length > 0) {
						await filterOptions(
							selectedItem,
							responseData,
							latestButtonFilterType,
							filterObj.filter,
							"reload",
							response
						);
					}
				}
				if (responseComparison && responseComparison.data && responseComparison.data.length > 0) {
					const responseDataComparison = responseComparison.data;
					setCensusAverageComparison(responseComparison.censusAverage);
					setCensusByPeriodComparison(responseComparison?.censusByPeriod || []);
					if (responseComparison.censusByFacility) {
						setCensusByFacilityComparison(responseComparison?.censusByFacility || []);
					}
					let chartDataArr = [];

					if (
						filters.type === OVERALL_CARDS_TYPE.TOTAL_INCOMING ||
						filters.type === OVERALL_CARDS_TYPE.TOTAL_OUTGOING
					) {
						chartDataArr = await chartsData(responseDataComparison, filterObjComparison.filter);
					} else if (
						filtersComparison?.transferType === OVERALL_CARDS_TYPE.TOTAL ||
						filtersComparison?.type === OVERALL_CARDS_TYPE.TOTAL
					) {
						chartDataArr = await chartsOverallData(responseDataComparison, {
							startDate: startDateComparison,
							endDate: endDateComparison,
						});
					} else {
						chartDataArr = await chartsData(responseDataComparison, filterObjComparison.filter);
					}
					const latestChartData = await chartGroupBy(
						chartDataArr,
						latestButtonFilterType,
						responseComparison.censusAverage,
						responseComparison?.censusByPeriod,
						filterObjComparison.filter
					);
					setChartDataComparison({
						mainData: responseDataComparison,
						filterPatients: responseDataComparison,
						filterData: latestChartData,
					});

					let originalChartData = [];
					if (
						responseComparison.ninetyDaysData &&
						responseComparison.ninetyDaysData.length > 0 &&
						filtersComparison?.type === "ninetyDaysData"
					) {
						originalChartData = responseComparison.ninetyDaysData;
					}
					if (filterDataComparison.length > 0 && originalChartData.length > 0) {
						const latestFilterData = filterDataComparison
							.map((eleFilter) => {
								const selectedEle = _.find(originalChartData, { _id: eleFilter._id });
								if (selectedEle) {
									return {
										...eleFilter,
										admissionIds: selectedEle.admissionIds,
										ids: selectedEle.ids,
									};
								} else {
									return null;
								}
							})
							.filter((item) => item);
						setFilterDataComparison(latestFilterData);
					}
					if (selectedItem.length > 0) {
						await filterOptions(
							selectedItem,
							responseDataComparison,
							latestButtonFilterType,
							filterObjComparison.filter,
							"reload",
							responseComparison
						);
					}
				}
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		},
		[buttonFilterType, filterData, filterDataComparison, filters, filtersComparison, selectedItem]
	);

	// * added comparison
	const reset = () => {
		setSelectedItem([]);
		setDefaultPatientData([]);
		setFilterData([]);

		setSelectedItemComparison([]);
		setDefaultPatientDataComparison([]);
		setFilterDataComparison([]);
	};

	// * added comparison
	const handleToggle = useCallback(
		async (value) => {
			const currentIndex = selectedItem.indexOf(value);
			const newChecked = [...selectedItem];

			if (currentIndex === -1) {
				newChecked.push(value);
			} else {
				newChecked.splice(currentIndex, 1);
			}
			if (newChecked.length > 0) {
				await Promise.all([
					filterOptions(newChecked, chartData.mainData, buttonFilterType),
					filterOptions(
						newChecked,
						chartDataComparison.mainData,
						buttonFilterType,
						undefined,
						undefined,
						undefined,
						true
					),
				]);
			} else {
				setChartData((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
				setChartDataComparison((prevState) => ({
					...prevState,
					filterData: [],
					filterPatients: [],
				}));
			}
			setSelectedItem(newChecked);
		},
		[selectedItem, chartData.mainData, buttonFilterType, chartDataComparison.mainData]
	);

	// * added comparison
	const handleClickFilter = useCallback(
		async (type, filterType = null) => {
			setSelectedFilter(type);
			setButtonFilterType(filterType);
			await Promise.all([
				updateChartArrData(chartData.filterPatients, filterType),
				updateChartArrData(chartDataComparison.filterPatients, filterType, undefined, undefined, true),
			]);
			if (selectedItem.length > 0) {
				await Promise.all([
					filterOptions(selectedItem, chartData.mainData, filterType),
					filterOptions(selectedItem, chartDataComparison.mainData, filterType, undefined, undefined, undefined, true),
				]);
			}
		},
		[
			updateChartArrData,
			chartData.filterPatients,
			chartData.mainData,
			chartDataComparison.filterPatients,
			chartDataComparison.mainData,
			selectedItem,
		]
	);

	// * added comparison
	const handleChartLabelType = useCallback(
		async (type) => {
			setQueryFilters((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			setQueryFiltersComparison((prevState) => ({
				...prevState,
				chartPointType: type,
			}));
			await Promise.all([
				updateChartArrData(chartData.filterPatients, null),
				updateChartArrData(chartDataComparison?.filterPatients, null, undefined, true),
			]);
		},
		[updateChartArrData, chartData.filterPatients, chartDataComparison?.filterPatients]
	);

	// * added comparison
	const handleToggleAll = useCallback(
		async (value) => {
			if (value === "all") {
				const ids = filterData?.map((a) => a._id);
				await Promise.all([
					filterOptions(ids, chartData.mainData, buttonFilterType),
					filterOptions(ids, chartDataComparison.mainData, buttonFilterType, undefined, undefined, undefined, true),
				]);
				setSelectedItem([...ids]);
			} else {
				if (filters && filters.selectedFilterData && filters.selectedFilterData.length > 0) {
					await filterOptions(filters.selectedFilterData, chartData.mainData, buttonFilterType);
					setSelectedItem([...filters.selectedFilterData]);
				} else {
					setChartData((prevState) => ({
						...prevState,
						filterData: [],
						filterPatients: [],
					}));
					setSelectedItem([]);
				}
			}
		},
		[filterData, chartData, buttonFilterType]
	);

	const getFieldNames = (row) => {
		return getOverallFieldNames(row, filters?.relation, filters?.type, data);
	};

	const handleClose = useCallback(() => {
		dispatch(closeDetailsDialog());
	}, [dispatch]);

	const handleTabButton = useCallback((type) => {
		setSelectedTab(type);
	}, []);

	const getTotalText = useCallback(() => {
		if (filters.transferType === OVERALL_CARDS_TYPE.TOTAL_OUTGOING) {
			return "Total Outgoing Transfers";
		} else if (filters.transferType === OVERALL_CARDS_TYPE.TOTAL_INCOMING) {
			return "Total Incoming Transfers";
		} else {
			return "Total +/1";
		}
	}, [filters]);

	const onClickReport = useCallback(
		(title, automaticallyReport) => {
			handleOnClickReport({
				selectedTab,
				filters,
				chartData: {
					...chartData,
					filterPatients: patientDataOrderBy(chartData?.filterPatients, order, orderBy)
				},
				selectedFilter,
				tableElementRef,
				buildingElementRef,
				title,
				queryFilters,
				selectedItem,
				filterData,
				automaticallyReport,
				buttonFilterType,
				tableFilterType,
				orderBy,
				order,
				activeFacilitiesCount: activeFacilities?.length
			});
		},
		[
			selectedTab,
			filters,
			chartData,
			tableElementRef,
			buildingElementRef,
			queryFilters,
			selectedItem,
			filterData,
			buttonFilterType,
			tableFilterType,
			orderBy,
			order,
			activeFacilities
		]
	);

	// * added comparison
	useEffect(() => {
		reset();
		if (filters && filters.filter) {
			const daysFilter = chartFilterPermission(filters.filter);
			const daysDiff = dayDifferent(filters.filter);
			setQueryFilters({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filters.filter.startDate,
				endDate: filters.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
		if (filtersComparison && filtersComparison?.filter) {
			const daysFilter = chartFilterPermission(filtersComparison?.filter);
			const daysDiff = dayDifferent(filtersComparison?.filter);
			// Todo: @Orfey Kostadinov I have comment below code due to graph filter not working, so please review it.
			// TODO: @chiragParmar93 please check
			setQueryFiltersComparison({
				filterButtons: daysFilter,
				days: daysDiff,
				startDate: filtersComparison?.filter.startDate,
				endDate: filtersComparison?.filter.endDate,
				chartPointType: CHART_POINT_TYPE.TOTAL,
			});
			getPatientChartsData();
		}
	}, [filters, filtersComparison]);

	const handleExcelReport = useCallback((selectedColumns = []) => {
		handleGenerateExcelReport({
			data: chartData?.filterPatients,
			filters,
			pageType: DASHBOARD_FILTER_TYPE.OVERALL,
			getFieldNames,
			title,
			censusAverage,
			bedCapacity: dbData?.bedCapacity || 0,
			questions,
			selectedColumns,
			orderBy,
			order,
			activeFacilitiesCount: activeFacilities?.length
		});
	}, [chartData?.filterPatients, filters, getFieldNames, handleGenerateExcelReport, title, censusAverage, dbData, questions, orderBy, order, activeFacilities]);

	const dropDownComponent = () => <DropDown
		options={OverallAdtOptions}
		selected={tableFilterType}
		setOption={setTableFilterType}
		width={`275`}
		style={{ zIndex: 100 }}
		size={`medium`}
		className={"table-filter-dropdown"}
	/>

	// below code use for search filter in table
	const [searchTerm, setSearchTerm] = useState('');
	const [totalHighlightedCount, setTotalHighlightedCount] = useState(0);
	const [debounceHighlightedCount, setDebounceHighlightedCount] = useState(0);
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
	const [highlightedCountDefaultValue, setHighlightedCountDefaultValue] = useState(0);
	const [selectedHighlightedColumns, setSelectedHighlightedColumns] = useState([]);

	// useEffect(() => {
	// 	if (questions && questions.length > 0) {
	// 		setSelectedHighlightedColumns([...questions?.map((ele) => ele?.question?.accessor), ...tableDefaultQuestions.map((ele) => ele?.question?.accessor), "selectAll"]);
	// 	}
	// }, [questions]);

	const [filterType, setFilterType] = useState({ type: '', filter: '', operation: '' });

	useEffect(() => {
		const handlerCount = _.debounce(() => setDebounceHighlightedCount(totalHighlightedCount), 200);
		handlerCount();
		return () => handlerCount.cancel();
	}, [totalHighlightedCount]);

	useEffect(() => {
		const handler = _.debounce(() => {
			setDebouncedSearchTerm(searchTerm);
			setFilterType({ type: typeof searchTerm, filter: searchTerm, operation: '' });
		}, 300);
		handler();
		return () => handler.cancel();
	}, [searchTerm]);

	const handleSearch = (event) => {
		const value = event.target.value;
		setSearchTerm(isNaN(value) || !value ? value : Number(value));
	};

	const handleHighlightedCount = useCallback((count) => {
		setTotalHighlightedCount(searchTerm ? count : 0);
	}, [searchTerm]);

	// end of below code use for search filter in table
	return (
		<ChartDialogContainer handleClose={handleClose} isOpen={isOpen}>
			<ChartDialogLeftSidebar
				loading={loading}
				filterData={filterData}
				selectedItem={selectedItem}
				handleToggle={handleToggle}
				title={title}
				handleToggleAll={handleToggleAll}
				selectedTab={selectedTab}
			/>
			<Grid item xs={10.7} style={{ padding: "30px" }}>
				<ChartDetailsTab
					selectedTab={selectedTab}
					handleTabButton={handleTabButton}
					queryFilters={queryFilters}
					handleOnClickReport={onClickReport}
					loading={loading}
					handleExcelReport={handleExcelReport}
					{...(selectedTab === CHART_TAB_BUTTON.TABLE && { tableFilterTypeOptions: dropDownComponent() })}
					setSelectedFacility={setSelectedFacility}
					selectedFacility={selectedFacility}
					questions={questions}
					tableFilterType={tableFilterType}
					pageType={PAGE_TYPE.OVERALL}
				/>
				{selectedTab === CHART_TAB_BUTTON.TABLE &&
					<ChartDetailsSearchBar
						searchTerm={searchTerm}
						handleSearch={handleSearch}
						setSearchTerm={setSearchTerm}
						highlightedCount={debounceHighlightedCount}
						filterType={filterType}
						setFilterType={setFilterType}
						selectedHighlightedColumns={selectedHighlightedColumns}
						setSelectedHighlightedColumns={setSelectedHighlightedColumns}
						selectedQuestions={questions}
					/>
				}
				{!loading && (
					<NoteContainer page={PAGE_TYPE.OVERALL} openFrom="dialog">
						{selectedTab === CHART_TAB_BUTTON.BUILDING && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartBuildingList
									page={PAGE_TYPE.OVERALL}
									data={chartData?.filterPatients || []}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									{...(filters.transferType === OVERALL_CARDS_TYPE.TOTAL && {
										type: "overall",
									})}
									filterCardType={filters.transferType}
									chartData={chartData}
									censusByFacility={censusByFacility}
									filterSelected={filters.filterSelected}
									filter={filters}
									buildingElementRef={buildingElementRef}
									dataComparison={chartDataComparison?.filterPatients || []}
									relationComparison={filtersComparison?.relation}
									chartDataComparison={chartDataComparison}
									censusByFacilityComparison={censusByFacilityComparison}
									filterSelectedComparison={filtersComparison?.filterSelected}
									filterComparison={filtersComparison}
									isTotalCard={filters?.isTotalCard}
									filterListData={filters?.filterListData}
									typeCard={filters?.type}
								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.TABLE && (
							<Stack direction={"row"} sx={{ mt: 2, height: "99%" }}>
								<ChartTableList
									data={chartData.filterPatients}
									relation={filters?.relation}
									getFieldNames={getFieldNames}
									tableElementRef={tableElementRef}
									pageType={DASHBOARD_FILTER_TYPE.OVERALL}
									questions={questions}
									searchTerm={debouncedSearchTerm}
									handleHighlightedCount={handleHighlightedCount}
									highlightedCountDefaultValue={highlightedCountDefaultValue}
									filterType={filterType}
									selectedHighlightedColumns={selectedHighlightedColumns}
									orderData={{
										orderBy,
										setOrderBy,
										order,
										setOrder
									}}
									activeFacilities={activeFacilities}
								/>
							</Stack>
						)}
						{selectedTab === CHART_TAB_BUTTON.CHART && (
							<Stack direction={"row"} sx={{ mt: 2, height: "100%" }}>
								<CardDetailChart
									totalText={getTotalText()}
									data={chartData.filterData}
									filters={filters}
									handleChartLabelType={handleChartLabelType}
									censusAverage={censusAverage}
									total={chartData.filterPatients.length || 0}
									isChartTotalButton={isShowPercentage}
									setQueryFilters={setQueryFilters}
									queryFilters={queryFilters}
									handleClickFilter={handleClickFilter}
									selectedFilter={selectedFilter}
									reloadChartData={reloadChartData}
									isTotalText={filters.transferType !== OVERALL_CARDS_TYPE.TOTAL}
									selectedFacility={selectedFacility}
									isOverallTotal={filters.transferType === OVERALL_CARDS_TYPE.TOTAL || filters.type === OVERALL_CARDS_TYPE.TOTAL}
									dbData={dbData}
								/>
							</Stack>
						)}
					</NoteContainer>
				)}
				<ChartTabLoader loading={loading} />
			</Grid>
		</ChartDialogContainer>
	);
}
