export const DASHBOARD_FILTER_TYPE = {
    COMMUNITY_TRANSFER: 'communityTransfer',
    ADMISSION: 'admission',
    HOSPITAL: 'hospital',
    DECEASED: 'deceased',
    OVERALL: 'overall',
}

export const ADT_TABLE_TYPE = {
    CHART: 'chart',
    DAY_CHART: 'dayChart',
    ANALYSIS_CHART: 'analysisChart',
    TOTAL: 'total',
    GROUP: 'group',
    GROUP_ARRAY: 'groupArray',
    ALL: 'all',
}

export const CHART_FILTER_DAY_OF = {
    DAY: "day",
    MONTH: "month",
    WEEK: "week",
    QUARTER: "quarter",
    YEAR: "year",
    THIRTY: "30",
    SEVEN: "7",
};

export const FILTER_TYPES = {
    DAILY: "daily",
    WEEKLY: "weekly",
    MONTHLY: "monthly",
    QUARTERLY: "quarterly",
    YEARLY: "yearly",
    THIRTY_DAYS: "thirtyDays",
    SEVEN_DAYS: "sevenDays",
};

export const ADT_TYPES = {
    ADMISSIONS: 'admission',
    READMISSIONS: 'readmission',
    RETURN: 'return',
    TRANSFER: 'transfer',
    ADMISSION_OR_READMISSION: 'admissionOrReadmission',
}

export const ADT_VALIDATION_TYPES = {
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
    RETURN: 'return',  
    ALL: 'all',  
    DECEASED:"deceased",
    READMISSIONS: 'readmission',
    ADMISSIONS: 'admission',
    ADMISSION_OR_READMISSION: 'admissionOrReadmission',
    READMISSIONS_RETURN: 'readmissionReturn',
}

export const ADT_SUB_TYPES = {
    UNPLANNED: "Unplanned",
    PLANNED: "planned",
    SAFE_DISCHARGE: "safeDischarge",
    SNF: "SNF",
    AMA: "AMA",
    DECEASED: "deceased",
    PLANNED_HOSPITAL_TRANSFER: "plannedHospitalTransfer",
    UNPLANNED_HOSPITAL_TRANSFER: "unplannedHospitalTransfer",
}

export const TOTAL_TYPE = {
    MAIN: "main",
    FILTER: "filter",
}

export const COLORS_BOX_CODE = {
    RED: "#F30000",
    ORANGE: "#FF7A00",
    YELLOW: "#FFD600",
    LIGHT_GREEN: "#00FFC2",
    GREEN: "#75E704",
    BLUE: "#0038FF",
}

export const EXCEL_HOSPITAL_LABELS = [
    "Last Name",
    "First Name",
    "Date of birth",
    "Type",
    "Date of transfer",
    "Time of transfer",
    "Unit",
    "Doctor",
    "Dx",
    "Hospital",
    "Payer Source/Insurance",
    "Nurse",
    "Notes",
    "Was admitted to hospital",
    "Returned from hospital",
    "Assisted living transferred to",
    "Type",
    "# of days in hospital"
]

export const EXCEL_ADMISSIONS_LABELS = [
    "Last Name",
    "First Name",
    "Date of birth",
    "Type",
    "Date of transfer",
    "Time of transfer",
    "Unit",
    "Doctor",
    "Dx",
    "Hospital",
    "Payer Source/Insurance",
    "Nurse",
    "Notes",
    "Type",
    "# of days in hospital"
]

export const ALERT_TYPE_TYPE = {
    WEEKS: 'weeks',
    BI_WEEKS: 'biWeeks',
    MONTHS: 'months',
}