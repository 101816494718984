import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SearchIcon from "@mui/icons-material/Search";
import {
	Checkbox,
	ClickAwayListener,
	IconButton,
	InputAdornment,
	Skeleton,
	TextField,
	Typography,
} from "@mui/material";
import classNames from "classnames";
import { Children, cloneElement, useEffect, useState } from "react";
import ExpandPopupSVG from "../../../assets/svgs/expand-popup.svg";
import MinimizeSVG from "../../../assets/svgs/minimize.svg";
import PopupPlusSVG from "../../../assets/svgs/popup-plus.svg";
import CardTooltipContent from "../../dashboard/card/CardTooltipContent";
import InfoIcon from "../../icon/InfoIcon";
import HtmlTooltip from "../HtmlTooltip";
import ReportButton from "../ReportButton";
import styles from "./ADTCard.module.scss";
import RemoveCardButton from "./RemoveCardButton";

const ADTCard = ({
	children,
	flex,
	title,
	secondaryTitle = "",
	secondaryTitleError = "",
	secondaryTitleClassName = "",
	minimizable,
	itemAlign,
	isDetailsOpen = true,
	isDisabled = false,
	handleOnClickDetails,
	taggedList,
	loading = false,
	isSlider = false,
	item,
	cardFilter,
	filters,
	reloadAdmissionFilter,
	helperText,
	priorityNumber = null,
	defaultADTData,
	handleOnClickReport,
	handleSendAutomaticallyReport,
	canBeSearched,
	specificData,
}) => {
	const [showDetails, setShowDetails] = useState(false);
	const [showSearch, setShowSearch] = useState(false);
	const [searchValue, setSearchValue] = useState("");
	const [triggerSelectAll, setTriggerSelectAll] = useState(null);
	const [numberOfCheckedItems, setNumberOfCheckedItems] = useState(0);
	const [allItemsChecked, setAllItemsChecked] = useState(false);

	useEffect(() => {
		const allItemsCheckedTemp = numberOfCheckedItems === specificData?.tableData?.length;

		if (allItemsCheckedTemp !== allItemsChecked) {
			setAllItemsChecked(allItemsCheckedTemp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [specificData, numberOfCheckedItems]);

	useEffect(() => {
		const numberOfCheckedItemsTemp = specificData?.selectedIds?.length;
		if (numberOfCheckedItemsTemp !== numberOfCheckedItems) {
			setNumberOfCheckedItems(numberOfCheckedItemsTemp);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [specificData]);

	useEffect(() => {
		if (allItemsChecked && triggerSelectAll === false) {
			setTriggerSelectAll(true);
		} else if (!allItemsChecked && triggerSelectAll === true) {
			setTriggerSelectAll(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allItemsChecked]);

	const childrenWithProps = children
		? Children.map(children, (child) =>
			cloneElement(child, {
				searchValue,
				selectAll: allItemsChecked,
				triggerSelectAll,
				setTriggerSelectAll,
			})
		)
		: null;

	useEffect(() => {
		setShowDetails(isDisabled ? false : isDetailsOpen);
	}, [isDetailsOpen, isDisabled]);

	return (
		<div
			className={classNames(styles.ADTCard, isDisabled && styles.disabled)}
			style={{ flex, height: `${showDetails ? "100%" : "fit-content"}` }}
		>
			<div className={(classNames("ffmar fs15 fw700"), styles.cardHeaderContainer)}>
				<div className={classNames("df aic ffmar fs15 fw700", styles.cardHeader)}>
					{showSearch && canBeSearched && !loading ? (
						<ClickAwayListener
							mouseEvent="onMouseDown"
							touchEvent="onTouchStart"
							onClickAway={() => {
								showSearch && setShowSearch(false);
							}}
						>
							<TextField
								placeholder="Search"
								type="search"
								variant="outlined"
								fullWidth
								size="small"
								value={searchValue}
								onChange={(e) => {
									setSearchValue(e.target.value);
								}}
								sx={{ height: "29px" }}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</ClickAwayListener>
					) : (
						<>
							{canBeSearched && ((minimizable && showDetails) || !minimizable) && (
								<Checkbox
									disableRipple
									edge="end"
									icon={<CheckCircleOutlineIcon />}
									checkedIcon={<CheckCircleIcon />}
									onChange={() =>
										setTriggerSelectAll((oldState) => {
											if (oldState === null) {
												return true;
											}
											return !oldState;
										})
									}
									checked={allItemsChecked}
									sx={{ p: 0, mr: "5px", pl: 0, ml: "-7px" }}
								/>
							)}

							<div className={styles.cardTooltipIcon}>
								<HtmlTooltip content={<CardTooltipContent />}>
									<IconButton disableFocusRipple={true} disableRipple={true}>
										<InfoIcon />
									</IconButton>
								</HtmlTooltip>
							</div>

							<Typography className={styles.title} variant="subtitle1" component="div">
								{title} &nbsp; &nbsp;<b>{priorityNumber && `#${priorityNumber}`}</b>
								{secondaryTitle && <div className={classNames("fs10", styles.secondaryTitle)}>{secondaryTitle}</div>}
								{secondaryTitleError && (
									<div className={classNames("fs10", styles.secondaryTitle, secondaryTitleClassName)}>
										{secondaryTitleError}
									</div>
								)}
								{helperText && <div className={classNames("fs10", styles.secondaryTitle)}>{helperText}</div>}
							</Typography>

							<div className={`df aic mla`}>
								{canBeSearched && (
									<IconButton
										disableFocusRipple={true}
										disableRipple={true}
										sx={{
											width: 29,
											height: 29,
											mt: "-8px",
											mr: 1,
											borderRadius: "7px",
											border: loading ? "none" : "1px solid grey",
											backgroundColor: loading ? "transparent" : searchValue !== "" ? "#4879f5" : "#fff",
											color: loading ? "inherit" : "black",
											...(loading
												? {}
												: {
													":hover": {
														backgroundColor: "grey",
													},
													cursor: "pointer",
												}),
										}}
										onClick={() => !loading && setShowSearch(true)}
										className={loading && "skeleton"}
									>
										{!loading && <SearchIcon sx={{ width: "20px", height: "20px" }} />}
									</IconButton>
								)}
								{handleOnClickReport && !loading && (
									<ReportButton
										handleOnClickReport={handleOnClickReport}
										handleSendAutomaticallyReport={handleSendAutomaticallyReport}
										loading={loading}
										title={title}
										sx={{
											mt: "-2px",
											height: 28.8,
											width: 29,
										}}
									/>
								)}
								{!isDisabled && (
									<div
										className="cp"
										onClick={() => {
											handleOnClickDetails(item);
										}}
									>
										<ExpandPopupSVG fill="#4879F5" />
									</div>
								)}
								{minimizable && (
									<div
										className="p-l-6 p-r-5 p-t-5 p-b-5 m-l-15"
										style={{ cursor: "pointer" }}
										onClick={() => setShowDetails(!showDetails)}
									>
										{showDetails ? <MinimizeSVG /> : <PopupPlusSVG />}
									</div>
								)}
								<div className="p-r-5 p-t-5 p-b-5 m-l-10">
									<RemoveCardButton
										reloadAdmissionFilter={reloadAdmissionFilter}
										filters={filters}
										item={item}
										cardFilter={cardFilter}
										defaultADTData={defaultADTData}
									/>
								</div>
							</div>
						</>
					)}
				</div>
				{taggedList && taggedList}
			</div>
			{!isSlider && showDetails && isDisabled === false && (
				<div className={classNames("df", styles.cardContent, itemAlign ? itemAlign : "aic")}>
					{loading ? <Skeleton variant="rectangular" width={"100%"} height={"100%"} /> : childrenWithProps}
				</div>
			)}
			{isSlider && showDetails && isDisabled === false && childrenWithProps}
		</div>
	);
};

export default ADTCard;
