/* eslint-disable react-hooks/exhaustive-deps */
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HistoryIcon from "@mui/icons-material/History";
import { Box, Button, Divider, IconButton, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { addDays, subDays } from "date-fns";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { firstAndLastDateOfInput, getCensusFirstDate } from "../../../services/data-input.service";
import { setFilterDateRange as setAdmissionDateFilter } from "../../../store/reducers/admission.slice";
import {
	setCustomPercentage,
	setCustomPercentageLabel,
	setEndDateOfADT,
	setFilterDateUpdate,
	setLastADTDate,
} from "../../../store/reducers/common.slice";
import { setFilterDateRange as setCommunityDateFilter } from "../../../store/reducers/community-transfer.slice";
import { setFilterDateRange as setDescentDateFilter } from "../../../store/reducers/deceased.slice";
import { setFilterDateRange as setHospitalDateFilter } from "../../../store/reducers/hospital.slice";
import { setFilterDateRange as setOverallDateFilter } from "../../../store/reducers/overall.slice";
import { setFilterDateRange as setQuickGlaceDateFilter } from "../../../store/reducers/quick-glace.slice";
import {
	dateFormat,
	toDisplayFullDate,
	toDisplayTime,
	toDisplayUTCDate,
	toSaveDate,
} from "../../../utilis/date-formats";
import ValidationADT from "../../landing/facility/data/add-patient/ValidationADT";
import DateRangePickerComponent from "./dateRangePicker/DateRangePickerComponent";
import NoOverlapDialog from "./NoOverlapDialog";
import { getEndDateOfADTData } from "../../../services/api/facility-manually-end-date-adt";
import { setIsComparisonRangeSet, setIsOverLapComparison, setIsOverLapDateMinimum, setManuallyClickComparison, setRemoveCompareFilter } from "../../../store/reducers/comparisonReducers/comparison.slice";
import useComparisonFunctions from "../../comparison-popup/useComparisonFunctions";
import TrendAnalysisOffDialog from "../../landing/facility/data/add-patient/TrendAnalysisOffDialog";
import { PAGE_TYPE } from "../../../types/pages.type";

const FilterContainer = styled(Box)(() => ({
	display: "flex",
	position: "relative",
	width: "100%",
	justifyContent: "space-evenly",
	background: "#FFFFFF",
	boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.06)",
	borderRadius: "8px",
	height: "60px",
	alignItems: "center",
	padding: "15px 0px",
}));

const DateFilterComponent = (props) => {
	const dispatch = useDispatch();
	const { filter, handleFilterUpdate } = props;
	const [isOPenDateRange, setIsOPenDateRange] = useState(false);
	const [state, setState] = useState([{ ...filter }]);
	const [censusDate, setCensusDate] = useState(null);
	const [invalidDateMsg, setInvalidDateMsg] = useState(null);
	const [isTrendAnalysisOff, setIsTrendAnalysisOff] = useState(null);
	const activeFacilities = useSelector((state) => state.activeFacilities.facilities);
	const { isFilterDateUpdate } = useSelector((state) => state.common);
	const { isOverLapDateMinimum, isOverLapComparison, isComparisonRangeSet, rangesSet, comparisonRanges: ranges } = useSelector((state) => state.comparison);

	const [firstAndLastDate, setFirstAndLastDate] = useState(null);
	const [anchorElHistory, setAnchorElHistory] = useState(null);
	const [isOverLapDate, setIsOverLapDate] = useState(null);
	const [loadingComparison, setLoadingComparison] = useState(false); // eslint-disable-line

	const { checkComparisonData } = useComparisonFunctions({
		forPage: props?.page,
		setLoadingComparison,
	});

	const handleClickHistory = (event) => {
		setAnchorElHistory(event.currentTarget);
	};

	const handleCloseHistory = () => {
		setAnchorElHistory(null);
	};
	const openHistoryPopover = Boolean(anchorElHistory);
	const idHistory = openHistoryPopover ? "simple-popover" : undefined;

	const fetchFirstAndLastDateOfInput = async (endDateOfADT) => {
		if (activeFacilities.length > 0) {
			const firstAndLastData = await firstAndLastDateOfInput({
				facilityIds: activeFacilities,
			});

			if (firstAndLastData) {
				dispatch(setCustomPercentage(firstAndLastData?.customPercentage));
				dispatch(setCustomPercentageLabel(firstAndLastData?.customPercentageLabel));
				const toDate = endDateOfADT && moment(firstAndLastData?.toDate).isBefore(endDateOfADT) ? endDateOfADT : firstAndLastData?.toDate;
				firstAndLastData.toDate = toDate;
				dispatch(setLastADTDate(toDate));
			}

			setFirstAndLastDate(firstAndLastData);
			if (isFilterDateUpdate) {
				if (!firstAndLastData?.isAdtData) {
					let latestDate = {
						endDate: endDateOfADT ? endDateOfADT : toDisplayUTCDate(new Date()),
						key: "selection",
						startDate: toDisplayUTCDate(new Date()),
					};
					setState([latestDate]);
					handleFilterUpdate && handleFilterUpdate(true);
					await updateDateToAllDashboard({ ...latestDate, defaultComparison: null });

					setIsOverLapDate("No data entered for this facility yet");
				} else if (firstAndLastData && firstAndLastData.isOverlap === true) {

					let countDayData = moment.utc(firstAndLastData.toDate).diff(firstAndLastData.fromDate, "days");
					const countDay = Math.abs(countDayData);
					let latestDate;
					if (countDay > 30 || countDayData < 0) {
						latestDate = {
							endDate: toDisplayUTCDate(firstAndLastData.toDate),
							key: "selection",
							startDate: moment(toDisplayUTCDate(firstAndLastData.toDate)).subtract(29, "days").toDate(),
						};
					} else {
						let fromDate = firstAndLastData?.censusDate ? toDisplayUTCDate(firstAndLastData.censusDate) : toDisplayUTCDate(firstAndLastData.fromDate);
						latestDate = {
							endDate: toDisplayUTCDate(firstAndLastData.toDate),
							key: "selection",
							startDate: fromDate,
						};
					}

					setState([latestDate]);

					const defaultComparison = await defaultComparisonRange(latestDate, firstAndLastData, true);
					if (defaultComparison?.comparisonFilter) {

						await updateDateToAllDashboard({ ...latestDate, defaultComparison: null });
						setTimeout(() => {
							handleFilterUpdate && handleFilterUpdate(true);
							dispatch(setManuallyClickComparison({ range: defaultComparison?.comparisonFilter, isDefault: true }));
						}, props.page === PAGE_TYPE.ADMISSION ? 0 : 100);
					} else {
						handleFilterUpdate && handleFilterUpdate(true);
						await updateDateToAllDashboard({ ...latestDate, defaultComparison: null });
					}
				}
			} else {
				const defaultComparison = await defaultComparisonRange(filter, firstAndLastData, true);
				if (defaultComparison?.comparisonFilter) {
					await updateDateToAllDashboard({ ...filter, defaultComparison: null });
					setTimeout(() => {
						handleFilterUpdate && handleFilterUpdate(true);
						dispatch(setManuallyClickComparison({ range: defaultComparison?.comparisonFilter, isDefault: true }));
					}, props.page === PAGE_TYPE.ADMISSION ? 0 : 100);
				} else {
					handleFilterUpdate && handleFilterUpdate(true);
					await updateDateToAllDashboard({ ...filter, defaultComparison: null });
				}
			}
		}
	};

	const defaultComparisonRange = async (latestDate, firstAndLastDataDb = null, isDefault = false) => {
		console.log(isComparisonRangeSet, 'isComparisonRangeSet');
		
		if (!isComparisonRangeSet) {
			dispatch(setManuallyClickComparison(null));
			dispatch(setRemoveCompareFilter(false));
			return null
		}
		if (isDefault && rangesSet && ranges.length > 0) {
			const selectedRange = ranges[0];
			const comparisonFilterDefault = [
				{
					startDate: selectedRange?.startDate,
					endDate: selectedRange?.endDate,
					key: "selection",
				},
			];
			return { comparisonFilter: comparisonFilterDefault, isDefault }
		}

		const firstAndLastData = firstAndLastDataDb ? firstAndLastDataDb : firstAndLastDate;
		const censusDateDB = firstAndLastData?.fromDate ? firstAndLastData?.fromDate : censusDate;
		let defaultStartDate;
		let defaultEndDate;

		let countDayData = moment.utc(firstAndLastData.toDate).diff(firstAndLastData.fromDate, "days");
		const countDay = Math.abs(countDayData);


		if (countDay > 30 || countDayData < 0) {
			defaultStartDate = moment(toDisplayUTCDate(firstAndLastData.toDate)).subtract(29, "days").toDate();
			defaultEndDate = toDisplayUTCDate(firstAndLastData.toDate);
		} else {
			let fromDate = firstAndLastData?.censusDate ? toDisplayUTCDate(firstAndLastData.censusDate) : toDisplayUTCDate(firstAndLastData.fromDate);
			defaultStartDate = fromDate;
			defaultEndDate = toDisplayUTCDate(firstAndLastData.toDate);
		}

		const selectedRange = await checkComparisonData(latestDate.startDate, latestDate.endDate, censusDateDB, {
			defaultStartDate,
			defaultEndDate,
		});

		if (selectedRange === "no overlap") {
			if (!isOverLapComparison) {
				setIsTrendAnalysisOff(`Trend Analysis is unavailable because the data you’re viewing is over 30 days old. To re-enable this feature, include more recent data in your view.`);
				setTimeout(() => {
					dispatch(setIsOverLapComparison(true));
				}, 200);
			}
			dispatch(setManuallyClickComparison(null));
			dispatch(setRemoveCompareFilter(false));
			return null
		} else if (selectedRange === "no minimum range") {
			if (!isOverLapDateMinimum) {
				setTimeout(() => {
					dispatch(setIsOverLapDateMinimum(true));
				}, 200);
				setIsTrendAnalysisOff(`Trend analysis is unavailable because we do not have sufficient data from before your selected dates to provide a comprehensive analysis. To re-enable this feature automatically, please select a date range that includes at least the same amount of data for this facility prior to your selected date range.`);
			}
			dispatch(setManuallyClickComparison(null));
			dispatch(setRemoveCompareFilter(false));
			return null
		} else if (selectedRange) {
			const comparisonFilter = [
				{
					startDate: selectedRange.startDate,
					endDate: selectedRange.endDate,
					key: "selection",
				},
			];
			return { comparisonFilter, isDefault }
		} else {
			dispatch(setManuallyClickComparison(null));
			console.log('No comparison data available.');
			dispatch(setRemoveCompareFilter(false));
			return null
		}
	};

	useEffect(async () => {
		const fetchCensusDate = async () => {
			const facilityId = activeFacilities[activeFacilities.length - 1];
			const censusData = await getCensusFirstDate(facilityId);
			censusData?.date && setCensusDate(censusData.date);
		};
		if (activeFacilities && activeFacilities.length > 0) {
			fetchCensusDate();
			const res = await getEndDateOfADTData({ facilityIds: activeFacilities });
			let earliestDateData = null;
			if (res && res.latestEndDateOfADTManually.length > 0) {
				earliestDateData = res.latestEndDateOfADTManually?.reduce((earliest, current) => {
					return moment(earliest).isBefore(moment(current.endDateOfADT)) ? earliest : current.endDateOfADT;
				}, res.latestEndDateOfADTManually[0].endDateOfADT);
			}
			if (earliestDateData) {
				dispatch(setEndDateOfADT(earliestDateData));
			}
			fetchFirstAndLastDateOfInput(earliestDateData);
		}
	}, [activeFacilities]);

	const handleOnClickChooseDate = useCallback(() => {
		setIsOPenDateRange((prevState) => !prevState);
	}, []);

	const handleOnChange = useCallback(
		async (selection) => {
			let value = selection?.[0];

			const censusDateUI = firstAndLastDate?.fromDate ? toDisplayFullDate(firstAndLastDate?.fromDate) : censusDate;
			const censusFormatDate = firstAndLastDate?.fromDate ? toDisplayFullDate(firstAndLastDate?.fromDate) : toDisplayFullDate(censusDate);
			const isInvalidDate = moment(toSaveDate(selection?.[0].startDate)).isBefore(censusFormatDate);
			dispatch(setIsComparisonRangeSet(true));
			if (isInvalidDate) {
				setInvalidDateMsg(
					`we are unable to show you the data for the selected dates because it is prior to the initial input date ${toDisplayTime(
						censusDateUI
					)}. Please select dates that are after the initial input date`
				);
			} else {
				setIsOPenDateRange(false);
				setState(selection);
				dispatch(setFilterDateUpdate(false));
				let latestValue = {
					...value,
					startDate: moment(value?.startDate).startOf("day").toDate(),
					endDate: moment(value?.endDate).startOf("day").toDate(),
				};
				const defaultComparison = await defaultComparisonRange(latestValue);

				if (defaultComparison?.comparisonFilter) {
					await updateDateToAllDashboard({ ...latestValue, defaultComparison: null });
					setTimeout(() => {
						handleFilterUpdate && handleFilterUpdate(true);
						dispatch(setManuallyClickComparison({ range: defaultComparison?.comparisonFilter, isDefault: true }));
					}, props.page === PAGE_TYPE.ADMISSION ? 0 : 100);
				} else {
					await updateDateToAllDashboard({ ...latestValue, defaultComparison: null });
				}
			}
			dispatch(setIsOverLapDateMinimum(false));
			dispatch(setIsOverLapComparison(false));
		},
		[censusDate, firstAndLastDate, defaultComparisonRange]
	);

	const updateDateToAllDashboard = useCallback(
		(value) => {
			dispatch(setHospitalDateFilter(value));
			dispatch(setCommunityDateFilter(value));
			dispatch(setAdmissionDateFilter(value));
			dispatch(setDescentDateFilter(value));
			dispatch(setOverallDateFilter(value));
			dispatch(setQuickGlaceDateFilter(value));
		},
		[dispatch]
	);

	const handleOnClose = useCallback(() => {
		setIsOPenDateRange((prevState) => !prevState);
	}, []);

	const handleArrowClick = useCallback(
		async (type) => {
			dispatch(setIsComparisonRangeSet(true));
			if (state && state.length > 0) {
				const mkey = state[0]["key"];
				const mStartDate = state[0]["startDate"];
				const mEndDate = state[0]["endDate"];
				const date1 = moment(mEndDate);
				const date2 = moment(mStartDate);
				const days = date1.diff(date2, "days");

				if (days > 0) {
					if (type === "prev") {
						const newStartDatePrev = subDays(mStartDate, days);
						const newEndDatePrev = subDays(mEndDate, days);
						const mObject = {
							key: mkey,
							startDate: newStartDatePrev,
							endDate: newEndDatePrev,
						};
						setState([mObject]);
						const defaultComparison = await defaultComparisonRange(mObject);
						await updateDateToAllDashboard({ ...mObject, defaultComparison });
					} else {
						const newStartDateNext = addDays(mStartDate, days);
						const newEndDateNext = addDays(mEndDate, days);
						const mObject = {
							key: mkey,
							startDate: newStartDateNext,
							endDate: newEndDateNext,
						};

						setState([mObject]);
						const defaultComparison = await defaultComparisonRange(mObject);
						if (defaultComparison?.comparisonFilter) {
							await updateDateToAllDashboard({ ...mObject, defaultComparison: null });
							setTimeout(() => {
								handleFilterUpdate && handleFilterUpdate(true);
								dispatch(setManuallyClickComparison({ range: defaultComparison?.comparisonFilter, isDefault: true }));
							}, props.page === PAGE_TYPE.ADMISSION ? 0 : 100);
						} else {
							await updateDateToAllDashboard({ ...mObject, defaultComparison: null });
						}
					}
				}
				dispatch(setIsOverLapDateMinimum(false));
				dispatch(setIsOverLapComparison(false));
			}
		},
		[state, updateDateToAllDashboard, censusDate, firstAndLastDate]
	);

	return (
		<>
			{isTrendAnalysisOff && (
				<TrendAnalysisOffDialog
					selectedItem={{ errors: [isTrendAnalysisOff] }}
					close={() => setIsTrendAnalysisOff(null)}
					hideHeader={true}
				/>
			)}
			{invalidDateMsg && (
				<ValidationADT
					selectedItem={{ errors: [invalidDateMsg] }}
					close={() => setInvalidDateMsg(null)}
					hideHeader={true}
				/>
			)}
			<FilterContainer className={props.loading && "skeleton"}>
				{!props.loading && (
					<>
						<Tooltip title="Displays the date range of data entry" arrow>
							<IconButton
								aria-describedby={idHistory}
								variant="contained"
								onClick={handleClickHistory}
								color="primary"
								component="label"
								disableRipple
								sx={{ padding: { lg: "8px", md: "4px" } }}
							>
								<HistoryIcon />
							</IconButton>
						</Tooltip>
						<Popover
							id={idHistory}
							open={openHistoryPopover}
							anchorEl={anchorElHistory}
							onClose={handleCloseHistory}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "right",
							}}
						>
							{!firstAndLastDate?.isAdtData ? (
								<Typography sx={{ p: 2 }} variant="subtitle1">
									{activeFacilities.length > 1
										? "There is data that does not overlap for all facilities"
										: "No data entered for this facility yet"}
								</Typography>
							) : (
								<Typography sx={{ p: 2 }} variant="subtitle1">
									Start date of ADT : {toDisplayTime(firstAndLastDate?.fromDate)} <br />
									End date of ADT : {toDisplayTime(firstAndLastDate?.toDate)} <br />
								</Typography>
							)}
						</Popover>
						<Button onClick={handleOnClickChooseDate} sx={{ fontSize: { md: 13, lg: 12 } }}>
							Choose Date
						</Button>
						<Divider orientation="vertical" flexItem />

						<Stack direction={"row"} spacing={0} alignItems={"center"}>
							<IconArrowButton
								disabled={isOPenDateRange}
								handleClickArrow={() => handleArrowClick("prev")}
								icon={<ChevronLeftIcon />}
							/>

							<Stack
								direction={"row"}
								alignItems={"center"}
								onClick={handleOnClickChooseDate}
								sx={{ cursor: "pointer" }}
							>
								<IconButton size="medium" color="primary" variant="contained" disableRipple>
									<CalendarMonthIcon fontSize="medium" />
								</IconButton>
								<Typography>{filter && filter.startDate ? dateFormat(filter?.startDate) : "MM/DD/YYYY"}</Typography>
								<Typography sx={{ ml: { xs: 1, md: 1, lg: 2 }, mr: { xs: 1, md: 1, lg: 2 } }}>-</Typography>
								<Typography sx={{ mr: 1 }}>
									{filter && filter.endDate ? dateFormat(filter?.endDate) : "MM/DD/YYYY"}
								</Typography>
							</Stack>
							<IconArrowButton
								disabled={isOPenDateRange}
								handleClickArrow={() => handleArrowClick("next")}
								icon={<ChevronRightIcon />}
							/>
						</Stack>
						{isOPenDateRange && (
							<DateRangePickerComponent
								setIsOPenDateRange={setIsOPenDateRange}
								onChange={handleOnChange}
								selectedDate={state}
								handleCancel={handleOnClose}
								firstAndLastDate={firstAndLastDate}
							/>
						)}
					</>
				)}
			</FilterContainer>
			{isOverLapDate && (
				<NoOverlapDialog
					isOverLapDate={isOverLapDate}
					activeFacilities={activeFacilities}
					handleClose={() => setIsOverLapDate(null)}
				/>
			)}
		</>
	);
};

export default DateFilterComponent;

const IconArrowButton = ({ icon, handleClickArrow, disabled }) => {
	return (
		<IconButton
			disabled={disabled}
			color="primary"
			disableRipple
			onClick={handleClickArrow}
			sx={{
				background: "#4879F5",
				color: "#fff",
				width: 20,
				height: 20,
			}}
		>
			{icon}
		</IconButton>
	);
};
