import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import NotesIcon from '@mui/icons-material/Notes';
import Settings from '@mui/icons-material/Settings';
// import ClearIcon from "@mui/icons-material/Clear";
import HeaderMenuItem from './menu-item';
import { useDispatch, useSelector } from 'react-redux';
import { setIsOpenNote, setIsProjectionsPopup, setSelectedShortcutOptions } from '../../../store/reducers/common.slice';
import ComparisonPopup from '../../comparison-popup/ComparisonPopup';
import AutomaticallyReport from '../automatically-report/AutomaticallyReport';
import PickProjectionDaysPopup from '../PickProjectionDaysPopup/PickProjectionDaysPopup';
import { PAGE_TYPE } from '../../../types/pages.type';
import AlertReport from '../alert-report/AlertReport';
import { Badge } from '@mui/material';
import { createShortcutSetting } from '../../../services/settings.service';
// import CompareSideIcon from '../../../assets/svgs/CompareSideIcon.svg';
// import HtmlTooltip from '../HtmlTooltip';

export default function HeaderMenu({
    page,
    loading,
    setLoadingComparison,
    isAutomaticReportSaved,
    activeFacilities,
}) {
    const dispatch = useDispatch();

    // Anchor element for menu
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    // Helper function for toggleable states
    const useToggle = (initialState = false) => {
        const [state, setState] = React.useState(initialState);
        const toggleState = React.useCallback(() => setState((prev) => !prev), []);
        return [state, setState, toggleState];
    };

    // Popup state handlers
    const [isOpenComparison, setIsOpenComparison, toggleComparison] = useToggle();
    const [isOpenAutoReport, setIsOpenAutoReport, toggleAutoReport] = useToggle();
    const [isOpenPickProjectionDays, setIsOpenPickProjectionDays, togglePickProjectionDays] = useToggle();
    const [isOpenAlertReport, setIsOpenAlertReport, toggleAlertReport] = useToggle();

    // Dispatch actions
    const openProjectionsPopup = React.useCallback(() => {
        dispatch(setIsProjectionsPopup(true));
        togglePickProjectionDays();
    }, [dispatch, togglePickProjectionDays]);

    const { rangesSet } = useSelector((state) => state.comparison);
    const { projectionDays, selectedShortcutOptions, isNotePopup } = useSelector((state) => state.common);
    const [selectedSortCut, setSelectedSortCut] = React.useState(selectedShortcutOptions);

    const handleSaveShortcut = async (e) => {
        const isChecked = e.target.checked;
        const name = e.target.name;

        // Create the updated state
        const updatedState = {
            ...selectedSortCut, // Use the current selectedSortCut state
            [name]: isChecked,
        };

        // Update the state with the new selection
        setSelectedSortCut(updatedState);

        // Save the updated state to createShortcutSetting
        dispatch(setSelectedShortcutOptions(updatedState));
        await createShortcutSetting({ data: updatedState });
    }

    // if (loading) {
    //     return (<Box display="flex" justifyContent="flex-end" alignItems="center" p={1}>
    //         <div className="skeleton" style={{ width: "100%", height: "32px", borderRadius: "10px" }}></div>
    //     </Box>)
    // }


    const handleOnclickOpenNotes = React.useCallback(() => {
        dispatch(setIsOpenNote(!isNotePopup));
    }, [isNotePopup, dispatch]);

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', width: "auto" }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {page !== PAGE_TYPE.OVERALL && (
                        <AlertReport
                            page={page}
                            isOpenAlertReport={isOpenAlertReport}
                            setIsAlertReportOpen={setIsOpenAlertReport}
                            activeFacilities={activeFacilities}
                            loading={loading}
                            selectedSortCut={selectedSortCut}
                        />
                    )}
                    <AutomaticallyReport
                        isAutomaticReportSaved={true}
                        page={page}
                        loading={loading}
                        selectedSortCut={selectedSortCut}
                        isOpenAutoReport={isOpenAutoReport}
                        setIsOpenAutoReport={setIsOpenAutoReport}
                    />

                    {selectedSortCut.isPickProjectionDays && (
                        <PickProjectionDaysPopup
                            isOpenPickProjectionDays={isOpenPickProjectionDays}
                            setIsOpenPickProjectionDays={setIsOpenPickProjectionDays}
                            loading={loading}
                        />
                    )}

                    {selectedSortCut.isNotes && (
                        <Tooltip title={"Notes"} arrow>
                            <IconButton
                                onClick={() => handleOnclickOpenNotes()}
                                size="small"
                                disableFocusRipple={false}
                                disableRipple={false}
                                sx={{
                                    width: 30,
                                    height: 30,
                                    ml: 1,
                                    borderRadius: "4px",
                                    '&:hover': {
                                        backgroundColor: '#4879f5',
                                        color: "white",
                                    },
                                }}
                            >
                                <NotesIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}

                    <ComparisonPopup
                        forPage={page}
                        loading={loading}
                        setLoadingComparison={setLoadingComparison}
                        isOpenFromParent={isOpenComparison}
                        setIsOpenFromParent={setIsOpenComparison}
                        selectedSortCut={selectedSortCut}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title="Account settings" arrow>
                        {rangesSet || projectionDays != null ? (
                            <Badge variant="dot" overlap="circular" color="error">
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    sx={{ ml: 2 }}
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                >
                                    <Settings sx={{ width: 28, height: 28 }} />
                                </IconButton>
                            </Badge>
                        ) : (
                            <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <Settings sx={{ width: 28, height: 28 }} />
                            </IconButton>
                        )}
                    </Tooltip>
                </Box>
                <Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        slotProps={{
                            paper: {
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <HeaderMenuItem
                            handleClose={handleClose}
                            page={page}
                            handleOnclickTimeFrameConvert={openProjectionsPopup}
                            handleOnclickCompare={toggleComparison}
                            handleOnclickOpenAlertReport={toggleAlertReport}
                            handleOnclickOpenAutoReport={toggleAutoReport}
                            rangesSet={rangesSet}
                            projectionDays={projectionDays}
                            handleSaveShortcut={handleSaveShortcut}
                            selectedSortCut={selectedSortCut}
                            handleOnclickOpenNotes={handleOnclickOpenNotes}
                        />
                    </Menu>
                </Box>
            </Box>

            {isOpenPickProjectionDays && (
                <PickProjectionDaysPopup
                    isOpenPickProjectionDays={isOpenPickProjectionDays}
                    setIsOpenPickProjectionDays={setIsOpenPickProjectionDays}
                    loading={loading}
                    type="openFromParent"
                />
            )}
        </React.Fragment>
    );
}
