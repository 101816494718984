import { Box, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import moment from "moment";
import styles from "./ColorBoxes.module.scss";


const DateDifference = ({ comparisonRanges }) => {
	if (comparisonRanges && comparisonRanges.length > 0) {
		const { startDate, endDate } = comparisonRanges[0];

		// Validate startDate and endDate before processing
		if (startDate && endDate) {
			const formattedStartDate = moment(startDate).format("MM/DD/YYYY");
			const formattedEndDate = moment(endDate).format("MM/DD/YYYY");
			const daysDifference = moment(endDate).diff(moment(startDate), "days");

			return (
				<Typography variant="caption" fontWeight="normal" color={"#fff"} sx={{
					fontSize: "10px",
				}}>
					{`${formattedStartDate} - ${formattedEndDate} (${daysDifference} days)`}
				</Typography>
			);
		}
	}

	return null // Fallback when no valid data
};

const TooltipTitle = ({ comparingAgainst, comparingAgainstScaled, numberOfDays, comparisonRanges }) => {
	return (
		<div className={styles.tooltipWrapper}>
			<div>
				<div>
					<Typography
						variant="body1"
						sx={{
							textDecoration: "underline",
							fontSize: "11px",
						}}
						fontWeight="bold"
						color={"#fff"}>
						Comparison date:
					</Typography>

					<DateDifference
						comparisonRanges={comparisonRanges}
					/>
					<Typography
						variant="body2"
						fontWeight="bold"
						color={"#fff"}
						sx={{
							fontSize: "14px"
						}}
					>
						{comparingAgainst || 0} Transfers
					</Typography>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: "2px",
						}}
					>
						<Typography
							fontWeight="normal"
							color={"#fff"}
							sx={{
								fontSize: "10px",
								fontStyle: "italic",
							}}
						>
							{numberOfDays || 0} Day Projection:
						</Typography>
						<Typography
							variant="caption"
							fontWeight="bold"
							sx={{
								fontSize: "13px",
								fontStyle: "italic",
							}}
							color={"#fff"}
						>
							{comparingAgainstScaled || 0} Transfers
						</Typography>
					</Box>
				</div>
			</div>
			{/* {numberOfDays ?? comparingAgainstScaled ? (
				<div>
					<div>
						{numberOfDays} days projection:{" "}
						<span className={styles.comparingAgainstSpan}>({comparingAgainstScaled || 0})</span>
					</div>
				</div>
			) : null} */}
		</div>
	);
};

// const TooltipTitleOld = ({ comparingAgainst, comparingAgainstScaled, numberOfDays, comparisonRanges }) => {
// 	return (
// 		<div className={styles.tooltipWrapper}>
// 			<div>
// 				<div>
// 					Compared to: <span className={styles.comparingAgainstSpan}>{comparingAgainst || 0}</span>
// 				</div>
// 			</div>
// 			{numberOfDays ?? comparingAgainstScaled ? (
// 				<div>
// 					<div>
// 						{numberOfDays} days projection:{" "}
// 						<span className={styles.comparingAgainstSpan}>({comparingAgainstScaled || 0})</span>
// 					</div>
// 				</div>
// 			) : null}
// 		</div>
// 	);
// };

export default function ColorBox({
	color,
	type = "div",
	barWidth,
	bar = null,
	sx,
	comparingAgainst,
	comparingAgainstScaled,
	numberOfDays,
}) {
	const { rangesSet, comparisonRanges } = useSelector((state) => state.comparison);

	if (color && rangesSet) {
		switch (type) {
			case "button":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
							/>
						}
						arrow
					>
						<Box
							component={"div"}
							sx={{
								background: color,
								width: "9px",
								height: "18px",
								borderRadius: "2px",
								position: "absolute",
								left: "3px",
								...sx,
							}}
						/>
					</Tooltip>
				);
			case "div":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
							/>
						}
						arrow
					>
						<Box
							component={"div"}
							sx={{
								display: "inline-block",
								background: color,
								width: "11px",
								height: "27px",
								borderRadius: "2px",
								ml: 0.3,
								...sx,
							}}
						></Box>
					</Tooltip>
				);

			case "bar":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
							/>
						}
						arrow
					>
						<rect
							width="9px"
							height="18px"
							fill={color}
							y={7}
							x={barWidth / 2 - 35}
						//transform={`translate(${barWidthCount},7)`}
						/>
					</Tooltip>
				);

			case "dayBar":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
							/>
						}
						arrow
					>
						<rect width="24px" height="9px" x={bar.width / 4} y={bar.height / 2 + 10} fill={color} />
					</Tooltip>
				);
			case "list":
				return (
					<Tooltip
						title={
							<TooltipTitle
								comparingAgainst={comparingAgainst}
								comparingAgainstScaled={comparingAgainstScaled}
								numberOfDays={numberOfDays}
								comparisonRanges={comparisonRanges}
							/>
						}
						arrow
					>
						<Box
							component={"div"}
							sx={{
								display: "inline-block",
								background: color,
								width: "9px",
								height: "18px",
								borderRadius: "2px",
								...sx,
							}}
						/>
					</Tooltip>
				);

			default:
				return null;
		}
	}
	return null;
}
